.animate {
  height: 100px;
  width: 100px;
  background-color: #c00;
  transition: all 1s ease;
  position: absolute;
  cursor: pointer;
  font: 13px/100px sans-serif;
  color: white;
  text-align: center;
}

                         /* ↓ just to position things  */
.animate.left   { left: 0;   top: 50%;  margin-top: -100px;}
.animate.right  { right: 0;  top: 50%;  }
.animate.top    { top: 0;    left: 50%; }
.animate.bottom { bottom: 0; left: 50%; margin-left: -100px;}


.animate.left.move {
  left: 100%; 
  transform: translate(-100%, 0);
}

.animate.right.move {
  right: 100%; 
  transform: translate(100%, 0);
}

.animate.top.move {
  top: 100%; 
  transform: translate(0, -100%);
}

.animate.bottom.move {
  bottom: 100%; 
  transform: translate(0, 100%);
}