$path: '/wp-content/themes/understrap/';
@font-face {
    font-family: 'Gilroy';
    src: url($path+'fonts/Gilroy/Gilroy-Thin.eot');
    src: url($path+'fonts/Gilroy/Gilroy-Thin.eot?#iefix') format('embedded-opentype'),
        url($path+'fonts/Gilroy/Gilroy-Thin.woff2') format('woff2'),
        url($path+'fonts/Gilroy/Gilroy-Thin.woff') format('woff'),
        url($path+'fonts/Gilroy/Gilroy-Thin.ttf') format('truetype'),
        url($path+'fonts/Gilroy/Gilroy-Thin.svg#Gilroy-Thin') format('svg');
    font-weight: 100;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Gilroy';
    src: url($path+'fonts/Gilroy/Gilroy-ThinItalic.eot');
    src: url($path+'fonts/Gilroy/Gilroy-ThinItalic.eot?#iefix') format('embedded-opentype'),
        url($path+'fonts/Gilroy/Gilroy-ThinItalic.woff2') format('woff2'),
        url($path+'fonts/Gilroy/Gilroy-ThinItalic.woff') format('woff'),
        url($path+'fonts/Gilroy/Gilroy-ThinItalic.ttf') format('truetype'),
        url($path+'fonts/Gilroy/Gilroy-ThinItalic.svg#Gilroy-ThinItalic') format('svg');
    font-weight: 100;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Gilroy';
    src: url($path+'fonts/Gilroy/Gilroy-UltraLight.eot');
    src: url($path+'fonts/Gilroy/Gilroy-UltraLight.eot?#iefix') format('embedded-opentype'),
        url($path+'fonts/Gilroy/Gilroy-UltraLight.woff2') format('woff2'),
        url($path+'fonts/Gilroy/Gilroy-UltraLight.woff') format('woff'),
        url($path+'fonts/Gilroy/Gilroy-UltraLight.ttf') format('truetype'),
        url($path+'fonts/Gilroy/Gilroy-UltraLight.svg#Gilroy-UltraLight') format('svg');
    font-weight: 200;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Gilroy';
    src: url($path+'fonts/Gilroy/Gilroy-UltraLightItalic.eot');
    src: url($path+'fonts/Gilroy/Gilroy-UltraLightItalic.eot?#iefix') format('embedded-opentype'),
        url($path+'fonts/Gilroy/Gilroy-UltraLightItalic.woff2') format('woff2'),
        url($path+'fonts/Gilroy/Gilroy-UltraLightItalic.woff') format('woff'),
        url($path+'fonts/Gilroy/Gilroy-UltraLightItalic.ttf') format('truetype'),
        url($path+'fonts/Gilroy/Gilroy-UltraLightItalic.svg#Gilroy-UltraLightItalic') format('svg');
    font-weight: 200;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Gilroy';
    src: url($path+'fonts/Gilroy/Gilroy-Light.eot');
    src: url($path+'fonts/Gilroy/Gilroy-Light.eot?#iefix') format('embedded-opentype'),
        url($path+'fonts/Gilroy/Gilroy-Light.woff2') format('woff2'),
        url($path+'fonts/Gilroy/Gilroy-Light.woff') format('woff'),
        url($path+'fonts/Gilroy/Gilroy-Light.ttf') format('truetype'),
        url($path+'fonts/Gilroy/Gilroy-Light.svg#Gilroy-Light') format('svg');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Gilroy';
    src: url($path+'fonts/Gilroy/Gilroy-LightItalic.eot');
    src: url($path+'fonts/Gilroy/Gilroy-LightItalic.eot?#iefix') format('embedded-opentype'),
        url($path+'fonts/Gilroy/Gilroy-LightItalic.woff2') format('woff2'),
        url($path+'fonts/Gilroy/Gilroy-LightItalic.woff') format('woff'),
        url($path+'fonts/Gilroy/Gilroy-LightItalic.ttf') format('truetype'),
        url($path+'fonts/Gilroy/Gilroy-LightItalic.svg#Gilroy-LightItalic') format('svg');
    font-weight: 300;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Gilroy';
    src: url($path+'fonts/Gilroy/Gilroy-Regular.eot');
    src: url($path+'fonts/Gilroy/Gilroy-Regular.eot?#iefix') format('embedded-opentype'),
        url($path+'fonts/Gilroy/Gilroy-Regular.woff2') format('woff2'),
        url($path+'fonts/Gilroy/Gilroy-Regular.woff') format('woff'),
        url($path+'fonts/Gilroy/Gilroy-Regular.ttf') format('truetype'),
        url($path+'fonts/Gilroy/Gilroy-Regular.svg#Gilroy-Regular') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Gilroy';
    src: url($path+'fonts/Gilroy/Gilroy-RegularItalic.eot');
    src: url($path+'fonts/Gilroy/Gilroy-RegularItalic.eot?#iefix') format('embedded-opentype'),
        url($path+'fonts/Gilroy/Gilroy-RegularItalic.woff2') format('woff2'),
        url($path+'fonts/Gilroy/Gilroy-RegularItalic.woff') format('woff'),
        url($path+'fonts/Gilroy/Gilroy-RegularItalic.ttf') format('truetype'),
        url($path+'fonts/Gilroy/Gilroy-RegularItalic.svg#Gilroy-RegularItalic') format('svg');
    font-weight: normal;
    font-style: italic;
    font-display: swap;
}


@font-face {
    font-family: 'Gilroy';
    src: url($path+'fonts/Gilroy/Gilroy-Medium.eot');
    src: url($path+'fonts/Gilroy/Gilroy-Medium.eot?#iefix') format('embedded-opentype'),
        url($path+'fonts/Gilroy/Gilroy-Medium.woff2') format('woff2'),
        url($path+'fonts/Gilroy/Gilroy-Medium.woff') format('woff'),
        url($path+'fonts/Gilroy/Gilroy-Medium.ttf') format('truetype'),
        url($path+'fonts/Gilroy/Gilroy-Medium.svg#Gilroy-Medium') format('svg');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Gilroy';
    src: url($path+'fonts/Gilroy/Gilroy-MediumItalic.eot');
    src: url($path+'fonts/Gilroy/Gilroy-MediumItalic.eot?#iefix') format('embedded-opentype'),
        url($path+'fonts/Gilroy/Gilroy-MediumItalic.woff2') format('woff2'),
        url($path+'fonts/Gilroy/Gilroy-MediumItalic.woff') format('woff'),
        url($path+'fonts/Gilroy/Gilroy-MediumItalic.ttf') format('truetype'),
        url($path+'fonts/Gilroy/Gilroy-MediumItalic.svg#Gilroy-MediumItalic') format('svg');
    font-weight: 500;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Gilroy';
    src: url($path+'fonts/Gilroy/Gilroy-SemiBold.eot');
    src: url($path+'fonts/Gilroy/Gilroy-SemiBold.eot?#iefix') format('embedded-opentype'),
        url($path+'fonts/Gilroy/Gilroy-SemiBold.woff2') format('woff2'),
        url($path+'fonts/Gilroy/Gilroy-SemiBold.woff') format('woff'),
        url($path+'fonts/Gilroy/Gilroy-SemiBold.ttf') format('truetype'),
        url($path+'fonts/Gilroy/Gilroy-SemiBold.svg#Gilroy-SemiBold') format('svg');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Gilroy';
    src: url($path+'fonts/Gilroy/Gilroy-SemiBoldItalic.eot');
    src: url($path+'fonts/Gilroy/Gilroy-SemiBoldItalic.eot?#iefix') format('embedded-opentype'),
        url($path+'fonts/Gilroy/Gilroy-SemiBoldItalic.woff2') format('woff2'),
        url($path+'fonts/Gilroy/Gilroy-SemiBoldItalic.woff') format('woff'),
        url($path+'fonts/Gilroy/Gilroy-SemiBoldItalic.ttf') format('truetype'),
        url($path+'fonts/Gilroy/Gilroy-SemiBoldItalic.svg#Gilroy-SemiBoldItalic') format('svg');
    font-weight: 600;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Gilroy';
    src: url($path+'fonts/Gilroy/Gilroy-Bold.eot');
    src: url($path+'fonts/Gilroy/Gilroy-Bold.eot?#iefix') format('embedded-opentype'),
        url($path+'fonts/Gilroy/Gilroy-Bold.woff2') format('woff2'),
        url($path+'fonts/Gilroy/Gilroy-Bold.woff') format('woff'),
        url($path+'fonts/Gilroy/Gilroy-Bold.ttf') format('truetype'),
        url($path+'fonts/Gilroy/Gilroy-Bold.svg#Gilroy-Bold') format('svg');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Gilroy';
    src: url($path+'fonts/Gilroy/Gilroy-BoldItalic.eot');
    src: url($path+'fonts/Gilroy/Gilroy-BoldItalic.eot?#iefix') format('embedded-opentype'),
        url($path+'fonts/Gilroy/Gilroy-BoldItalic.woff2') format('woff2'),
        url($path+'fonts/Gilroy/Gilroy-BoldItalic.woff') format('woff'),
        url($path+'fonts/Gilroy/Gilroy-BoldItalic.ttf') format('truetype'),
        url($path+'fonts/Gilroy/Gilroy-BoldItalic.svg#Gilroy-BoldItalic') format('svg');
    font-weight: bold;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Gilroy';
    src: url($path+'fonts/Gilroy/Gilroy-ExtraBold.eot');
    src: url($path+'fonts/Gilroy/Gilroy-ExtraBold.eot?#iefix') format('embedded-opentype'),
        url($path+'fonts/Gilroy/Gilroy-ExtraBold.woff2') format('woff2'),
        url($path+'fonts/Gilroy/Gilroy-ExtraBold.woff') format('woff'),
        url($path+'fonts/Gilroy/Gilroy-ExtraBold.ttf') format('truetype'),
        url($path+'fonts/Gilroy/Gilroy-ExtraBold.svg#Gilroy-ExtraBold') format('svg');
    font-weight: 800;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Gilroy';
    src: url($path+'fonts/Gilroy/Gilroy-ExtraBoldItalic.eot');
    src: url($path+'fonts/Gilroy/Gilroy-ExtraBoldItalic.eot?#iefix') format('embedded-opentype'),
        url($path+'fonts/Gilroy/Gilroy-ExtraBoldItalic.woff2') format('woff2'),
        url($path+'fonts/Gilroy/Gilroy-ExtraBoldItalic.woff') format('woff'),
        url($path+'fonts/Gilroy/Gilroy-ExtraBoldItalic.ttf') format('truetype'),
        url($path+'fonts/Gilroy/Gilroy-ExtraBoldItalic.svg#Gilroy-ExtraBoldItalic') format('svg');
    font-weight: 800;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Gilroy-Black';
    src: url($path+'fonts/Gilroy/Gilroy-Black.eot');
    src: url($path+'fonts/Gilroy/Gilroy-Black.eot?#iefix') format('embedded-opentype'),
        url($path+'fonts/Gilroy/Gilroy-Black.woff2') format('woff2'),
        url($path+'fonts/Gilroy/Gilroy-Black.woff') format('woff'),
        url($path+'fonts/Gilroy/Gilroy-Black.ttf') format('truetype'),
        url($path+'fonts/Gilroy/Gilroy-Black.svg#Gilroy-Black') format('svg');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Gilroy';
    src: url($path+'fonts/Gilroy/Gilroy-BlackItalic.eot');
    src: url($path+'fonts/Gilroy/Gilroy-BlackItalic.eot?#iefix') format('embedded-opentype'),
        url($path+'fonts/Gilroy/Gilroy-BlackItalic.woff2') format('woff2'),
        url($path+'fonts/Gilroy/Gilroy-BlackItalic.woff') format('woff'),
        url($path+'fonts/Gilroy/Gilroy-BlackItalic.ttf') format('truetype'),
        url($path+'fonts/Gilroy/Gilroy-BlackItalic.svg#Gilroy-BlackItalic') format('svg');
    font-weight: 900;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Gilroy';
    src: url($path+'fonts/Gilroy/Gilroy-Heavy.eot');
    src: url($path+'fonts/Gilroy/Gilroy-Heavy.eot?#iefix') format('embedded-opentype'),
        url($path+'fonts/Gilroy/Gilroy-Heavy.woff2') format('woff2'),
        url($path+'fonts/Gilroy/Gilroy-Heavy.woff') format('woff'),
        url($path+'fonts/Gilroy/Gilroy-Heavy.ttf') format('truetype'),
        url($path+'fonts/Gilroy/Gilroy-Heavy.svg#Gilroy-Heavy') format('svg');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Gilroy';
    src: url($path+'fonts/Gilroy/Gilroy-HeavyItalic.eot');
    src: url($path+'fonts/Gilroy/Gilroy-HeavyItalic.eot?#iefix') format('embedded-opentype'),
        url($path+'fonts/Gilroy/Gilroy-HeavyItalic.woff2') format('woff2'),
        url($path+'fonts/Gilroy/Gilroy-HeavyItalic.woff') format('woff'),
        url($path+'fonts/Gilroy/Gilroy-HeavyItalic.ttf') format('truetype'),
        url($path+'fonts/Gilroy/Gilroy-HeavyItalic.svg#Gilroy-HeavyItalic') format('svg');
    font-weight: 900;
    font-style: italic;
    font-display: swap;
}

@font-face {
  font-family: 'icomoon';
  src:  url($path+'fonts/icomoon/icomoon.eot?emicgn');
  src:  url($path+'fonts/icomoon/icomoon.eot?emicgn#iefix') format('embedded-opentype'),
    url($path+'fonts/icomoon/icomoon.ttf?emicgn') format('truetype'),
    url($path+'fonts/icomoon/icomoon.woff?emicgn') format('woff'),
    url($path+'fonts/icomoon/icomoon.svg?emicgn#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-cancel:before {
  content: "\e902";
}
.icon-clear:before {
  content: "\e901";
}
.icon-close:before {
  content: "\e900";
}
.icon-cross:before {
  content: "\ea0f";
}

html, body {
    font-family: 'Gilroy', sans-serif;
    h2 {
        &.elementor-heading-title {
            font-family: 'Gilroy' !important;
            font-weight: 900;
            &.elementor-size-default {
                font-size: 100px !important;
                @media (max-width: 767px) {
                    font-size: 50px !important;
                    line-height: 60px !important;
                }
            }
        }
    }
}
