@mixin alltransition($value:0.5s) {
    -webkit-transition: all $value ease;
    -moz-transition: all $value ease;
    -o-transition: all $value ease;
    transition: all $value ease;
}

@mixin centerabsolute() {
    position: absolute;
    -webkit-transform: translateX(-50%) translateY(-50%);
    -ms-transform: translateX(-50%) translateY(-50%);
    transform: translateX(-50%) translateY(-50%);
    display: block;
    left: 50%;
    top: 50%;
    z-index: 1;
    min-width: 70%;
    width: auto;
    height: auto;
}

@mixin poly_bottom($width:100%, $height:100%, $bgcolor:'#ddd', $clip:'', $zindex:0, $bottom:0) {
    content: '';
    width: $width;
    height: $height;
    background-color: $bgcolor;
    position: absolute;
    left: 50%;
    bottom: $bottom;
    -webkit-transform: translateX(-50%) translateY(0);
    -ms-transform: translateX(-50%) translateY(0);
    transform: translateX(-50%) translateY(0);
    clip-path: $clip; 
    z-index: $zindex;
}

@mixin poly_top($width:100%, $height:100%, $bgcolor:'#ddd', $clip:'', $zindex:0, $top:0) {
    content: '';
    width: $width;
    height: $height;
    background-color: $bgcolor;
    position: absolute;
    left: 50%;
    top: $top;
    -webkit-transform: translateX(-50%) translateY(0);
    -ms-transform: translateX(-50%) translateY(0);
    transform: translateX(-50%) translateY(0);
    clip-path: $clip; 
    z-index: $zindex;
}
