// This is an empty scss file for your custom styles - Please add your super duper awesome mega design styles here
@import "./fonts";
@import "./mixins";
@import "./animation";
@import "./layout";

body {

	.container,
	.container-fluid {
		max-width: 1700px;
		padding-left: 30px;
		padding-right: 30px;
	}

	#understrap_carousel_store_home {
    height: 150px;
    width: 300px;
    position: absolute;
    -webkit-transform: translateX(-50%) translateY(0);
    -ms-transform: translateX(-50%) translateY(0);
    transform: translateX(-50%) translateY(0);
    display: block;
    left: 50%;
    top: 0;
    z-index: 1;
    .carousel-item {
    	height: 150px;
    	width: 100%;
    	text-align: center;
    	a, p, span {
    		margin: 0;
    		color: #163926;
    	}
    	a {
    		display: flex;
    		align-items: center;
    		justify-content: center;
    		color: #fff;
    		width: 100%;
    		flex-direction: column;
    		transition: none;
    		opacity: 1;
    		.ucsh-title {
    			font-size: 18px;
    			font-weight: bold;
				white-space: nowrap;
    		}
    		.ucsh-sub {
    			margin-top: -7px;
    		}
    		.ucsh-s-time {
					font-size: 20px;
					font-weight: 200;
    		}
    	}
    }
	}
	.navbar {
		.sticky {
			#understrap_carousel_store_home {
				.carousel-item {
					a, p, span {
		    		color: $green_dk;
		    	}
				}
				&:hover {
					.ucsh-sub {
						border-bottom: 1px solid $green_dk;
					}
				}
			}
		}
		@media (max-width: 767px) {
			#understrap_carousel_store_home {
				display: none;
				z-index: 99999;
				bottom: 90px;
				top: unset;
				position: fixed;
				height: auto;
				width: 80%;
				.carousel-item {
					height: 90px;
				}
			}
		}
	}
	&.loaded-nav {
		@media (max-width: 767px) {
			#understrap_nav_menu {
				#understrap_carousel_store_home {
					display: none;
					.carousel-item {
						a, p, span {
							color: $green_dk;
						}
					}
					&:hover {
						.ucsh-sub {
							border-bottom: 1px solid $green_dk;
						}
					}
				}
			}
		}
	}
	.site {
		overflow: hidden;
	}
	.clip-bottom-left-up {
		clip-path: polygon(0 0, 100% 0, 100% 100%, 0% calc(100% - 10px));
		transform: translateZ(0);
	}
	.clip-top-left-to-right-up {
		clip-path: polygon(0 0, 100% 0, 100% 100%, 0% calc(100% - 10px));
		transform: translateZ(0);
	}
	.clip-top-left-to-right-down {
		clip-path: polygon(0% 0%,100% 10px,100% 100%,0% 100%);
		transform: translateZ(0);
	}
	.plain-p {
		font-family: 'Gilroy', sans-serif;
		p {
			font-family: 'Gilroy', sans-serif;
			color: $grey_dk;
		}
	}
	p {
		color: $black;
	}
	.tk-cta {
		&:hover {
			.elementor-cta {
				.elementor-cta__content {
					background-color: $green_dk!important;
				}
			}
		}
		&.enquire {
			.elementor-cta__content {
				clip-path: polygon(0% 35px, 100% 0%, 100% calc(100% - 35px), 0% 100%);
				transform: translateZ(0);
			}
		}
		&.quick-download {
			margin-top: -30px;
			.elementor-cta__content {
				padding-top: 40px;
				padding-bottom: 20px;
				clip-path: polygon(0% 35px, 100% 0%, 100% 100%, 0% 100%);
				transform: translateZ(0);
			}

		}
	}
	.tk-getting-here-btns {
		.elementor-column {
			margin-right: -8px;
		}
		.tk-cta {
			.elementor-cta {
				height: 86px;
				font-size: 20px;
				padding: 0;
				@media (max-width: 767px) {
					margin-bottom: 8px;
				}
				.elementor-cta__content {
					padding: 0;
					min-height: 86px;
					text-align: center;
					background-color: #4D7556;
					.elementor-cta__title {
						font-family: "Gilroy", Sans-serif;
						font-weight: bold;
						font-size: 20px;
						color: #FFFFFF;
					}
				}
			}
			&.by-car {
				.elementor-cta__content {
					clip-path: polygon(0% 0%,calc(100% - 15px) 0%, 100% 100%,0% 100%);
					transform: translateZ(0);
				}
			}
			&.by-bus {
				.elementor-cta__content {
					clip-path: polygon(0 0,100% 0,calc(100% - 15px) 100%,15px 100%);
					transform: translateZ(0);
					@media (max-width: 767px) {
						clip-path: polygon(0 0,100% 0,100% 100%,15px 100%);
					}
				}
			}
			&.by-bike {
				.elementor-cta__content {
					clip-path: polygon(15px 0%,100% 0,calc(100% - 15px) 100%,0% 100%);
					transform: translateZ(0);
					@media (max-width: 767px) {
						clip-path: polygon(0% 0%,100% 0,calc(100% - 15px) 100%,0% 100%);
					}
				}
			}
			&.on-foot {
				.elementor-cta__content {
					clip-path: polygon(15px 0%,100% 0,100% 100%,0% 100%);
					transform: translateZ(0);
				}
			}
		}
	}
	.elementor-widget-wrap {
		&>.elementor-element {
          margin-bottom: 1em;
		}
	}
	.poly-featured-image {
		clip-path: polygon(0 95px,100% 0,100% 93%,0 99%);
		transform: translateZ(0);
		.elementor-widget-container {
			padding-right: 30px;
			// clip-path: polygon(0% 95px, 100% 0%, 100% calc(100% - 25px), 0% 100%);
			@media (max-width: 767px) {
				margin-top: 0;
				padding-right: 0;
			}
			img {
				width: 100%;
				height: 1012px;
				object-fit: cover;
			}
		}
		// &:nth-child(1) {
		// 	.elementor-image {
		// 		margin-top: -70px;
		// 	}
		// }
		&.mobile {
			clip-path: polygon(0% 30px, 100% 0%, 100% 100%,0% calc(100% - 40px));
			transform: translateZ(0);
			.elementor-widget-container {
				// clip-path: polygon(0% 30px, 100% 0%, 100% 100%,0% calc(100% - 40px));
				img {
					height: auto;
				}
			}
		}

		&.left-tip {
			&.desktop {
				clip-path: polygon(0% 0%,100% 35px,100% calc(100% - 90px),0% 100%);
				transform: translateZ(0);
			}
		}

		&.right-tip {
			&.desktop {
				clip-path: polygon(0 35px,100% 0%,100% 100%,0 calc(100% - 90px));
				transform: translateZ(0);
			}
		}
	}

	.udisplay {
		&.desktop {
			@media (max-width: 767.98px) {
				display: none;
			}
		}
		&.mobile {
			@media (min-width: 768px) {
				display: none;
			}
		}
	}
	.main-content {
	    height: 100%;
	    width: 100%;
	    display: block;
	}
	#page-wrapper {
		min-height: 650px;
	}
	.main-content {
		position: relative;
	    left: 0;
	    @include alltransition(1.2s);
		&.open-nav {
			transform: translate(-100%, 0);
		}
	}
	#wrapper-navbar {
		position: relative;
		z-index: 99;
		@include alltransition(.5s);
		&.open-nav {
			.navbar {
				background-color: transparent;
				.navbar-brand {
					opacity: 0;
				}
			}
		}
	}
	#understrap-hero-banner {
		position: absolute;
		top: 0px;
		height: 860px;
		max-height: 860px;
		background-position: center center;
		background-repeat: no-repeat;
		background-size: cover;
		clip-path: polygon(0 0, 100% 0, 100% 100%, 0% 88%);
		transform: translateZ(0);
		transform: translateZ(0);
		width: 100%;
		overflow: hidden;
		@media (max-width: 767px) {
			clip-path: polygon(0 0, 100% 0, 100% 100%, 0% calc(100% - 80px));
		}
		img {
			opacity: 0;
		}
		.tv, .html5-vid, .vim-tv {
			width: 100%;
			height: 900px;
			position: relative;
			padding-bottom: 56.25%;
			iframe, video {
				width: 1513px;
			    height: 110%;
			    position: absolute;
			    top: -60px;
			    left: 50%;
				-webkit-transform: translateX(-50%) translateY(0);
				-ms-transform: translateX(-50%) translateY(0);
				transform: translateX(-50%) translateY(0);
				@media (min-width: 1514px) {
					width: 100%;
					// background-color: $black;
				}
			}
			&.hidden {
				display: none;
			}
		}
		.tv-cover {
			position: absolute;
			z-index: 2;
			width: 100%;
			height: 100%;
		}
		.vim-tv {
			iframe {
				top: -3px;
			}
		}
		#vid-error {
			@include centerabsolute();
			background-color: rgba(0,0,0,0.5);
			color: #fff;
			text-align: center;
			padding: 15px;
		}
		#uhb-content {
			@include centerabsolute();
			z-index: 3;
			min-width: 90%;
			color: #fff;
			.uhb-c-main-text {
				padding: 0px;
				text-align: center;
				font-size: 108px;
				font-weight: 900;
				line-height: 102px;
				text-shadow: 0 0px 16px rgba(0,0,0,.3);
				position: relative;
				width: 820px;
				margin: auto;
				@media (max-width: 850px) {
					width: 695px;
					font-size: 85px;
					line-height: 83px;
				}
				@media (max-width: 767px) {
					width: 100%;
					br {
						display: none;
					}
				}
				@media (max-width: 450px) {
					font-size: 60px;
					line-height: 62px;
				}
				@media (max-width: 350px) {
					font-size: 50px;
					line-height: 53px;
				}
				.uhb-c-sub-text {
					padding: 0;
					margin: 0;
					font-size: 34px;
					text-align: left;
					line-height: 8px;
					font-weight: 400;
					font-style: italic;
					position: absolute;
					left: 0;
					top: 0;
					margin-left: 96px;
					color: #fff; //$yellow_lt;
					@media (max-width: 850px) {
						font-size: 29px;
					}
					@media (max-width: 767px) {
						position: relative;
						width: 100%;
						margin: 0;
						text-align: center;
						line-height: 38px;
					}
					@media (max-width: 450px) {
						font-size: 25px;
					}
					@media (max-width: 350px) {
						font-size: 21px;
					}
				}
			}
			#uhb-play-video {
				display: inline-flex;
				width: 100%;
				margin-top: 40px;
				text-align: center;
				color: #fff;
				text-shadow: 0 0 7px rgba(0,0,0,0.5);
				align-items: center;
				justify-content: center;
				&:focus {
					outline: none;
					text-decoration: none;
				}
				i {
					font-size: 70px;
				}
				span {
					margin-left: 10px;
					font-weight: 700;
					font-size: 24px;
					text-transform: uppercase;
					@media (max-width: 450px) {
						font-size: 20px;
					}
				}
				&.html-play-btn {
					text-decoration: none;
					margin-top: 30px;
					i {
						font-size: 70px;
					}
					.loader {
						border: 10px solid #f3f3f3;
					    border-top: 10px solid #163926;
					    border-radius: 50%;
					    width: 60px;
					    height: 60px;
					  	animation: spin 2s linear infinite;
					}
				}
			}
		}
	}
	.txt-bold {
		font-weight: bold;
	}

	.sticky {
		@include alltransition(.5s);
	    position: fixed;
	    left: 0;
	    right: 0;
	    top: -300px;
	    z-index: 1000;
	    width: 100%;
	    background-color: rgba(255,255,255,.8);
	}

	.sticky + .content {
	  padding-top: 60px;
	}

	.understrap_social_elements {
		a {
			display: inline-flex;
			width: 50px;
			height: 50px;
			border: .5px solid $green_dk;
			border-radius: 50%;
			color: $green_dk;
			align-items: center;
			margin: 6px;
			&.social_instagram {
				i {
					opacity: 0;
				}
				background-image: url('../images/insta_default.svg');
				background-position: center;
				background-size: 127%;
				&:focus {
					background-image: url('../images/insta_click.svg');
				}
			}
			&.social_email {
				i {
					opacity: 0;
				}
				background-image: url('../images/email_default.svg');
				background-position: center;
				background-size: 127%;
				&:focus {
					background-image: url('../images/email_click.svg');
				}
			}
			&:hover {
				text-decoration: none;
				background-color: $yellow_lt;
				border: 2px solid $green_dk;
			}
			&:focus {
				text-decoration: none;
				background-color: $green_dk;
				border-color: $green_dk;
				color: $yellow_lt;
			}
			i {
				width: 100%;
				font-size: 27px;
				text-align: center;
			}
		}
	}

	#understrap_nav_menu {
		position: fixed;
		text-align: center;
		height: 100%;
		width: 100%;
		background-color: #fff;
		z-index: 9999;
		display: table;
		align-items: center;
		justify-content: space-evenly;
		.navbar-toggler {
			position: absolute!important;
		}
		.navdropdown-menus {
			width: 50%;
			a {
				@include alltransition(1s);
				&:hover {
					@include alltransition(1s);
				}
			}
		}
		#big-menu-container {
			padding-right: 22.5px;
			text-align: right;
			font-size: 50px;
			line-height: 55px;
			font-weight: 800;
			color: $green_dk;
			@media (max-width: 767px) {
				padding-right: 15px;
				font-size: 40px;
				line-height: 47px;
			}
			a {
				color: $green_dk;
				position: relative;
				&:hover {
					color: $green_dk;
				}
				&:focus, &:active {
					color: $black;
				}
			}
			a:before {
				content: '';
				width: 9px;
				height: 9px;
				position: absolute;
				right: -9px;
				bottom: 13px;
				background-color: #173826;
				border-radius: 50%;
				@media (max-width: 767px) {
					bottom: 11px;
				}
			}
		}
		.understrap_social_elements {
			position: absolute;
			bottom: 15px;
			width: 100%;
		}
		#understrap_additional_text {
			width: 50%;
			padding-right: 22.5px;
			text-align: right;
			font-size: 50px;
			font-weight: 700;
			color: $green_dk;
			.uat-wrap {
				width: 200px;
				float: right;
				line-height: 60px;
			}
		}
		#menu_icon {
			position: absolute;
			top: 50px;
			left: 70px;
			width: 45px;
		}
		// opacity: 0;
		@include alltransition(.5s);
		&.left   {
			left: 100%;
			top: 0;
			&.show {
				transform: translate(-100%, 0);
				@media (min-width: 768px) {
					#understrap_carousel_store_home {
						display: none;
					}
				}
			}
		}
		&.right  {
			right: 100%;
			top: 0;
			&.show {
				transform: translate(100%, 0);
			}
		}
		&.top    {
			top: 0;
			left: 50%;
			&.show {
				top: 100%;
				transform: translate(0, -100%);
			}
		}
		&.bottom {
			bottom: 0;
			left: 50%;
			margin-left: -100px;
			&.show {
				bottom: 100%;
				transform: translate(0, 100%);
			}
		}
		#primary-menu-container {
			width: 50%;
			padding-left: 22.5px;
			text-align: left;
			@media (max-width: 767px) {
				padding-left: 15px;
			}
			.nav-link {
				font-size: 22px;
				padding: 4px 0;
				text-transform: lowercase;
				color: $black;
				&:focus, &:active  {
					color: $black;
				}
				&:hover {
					text-decoration: underline;
				}
			}
			li {
				&.active, &:hover {
					.nav-link {
						color: $black;
					}
				}
			}
		}
		.menu-outer {
			display: table;
			width: 100%;
			height: 100%;
			min-height: 650px;
			.menu-inner-top {
				display: table;
				width: 100%;
				height: calc(100% - 250px);
				padding-top: 85px;
				.navdropdown-menus {
					display: table-cell;
					vertical-align: middle;
				}
			}
			.menu-inner-bottom {
				display: table;
				width: 100%;
				position: absolute;
				bottom: 100px;
				.derby-square-main-menu {
					#uhb-content {
						margin: 0 auto;
						display: table;
						color: #000 !important;
						.uhb-c-main-text {
							font-weight: 800;
							background: linear-gradient(to right,#000 6px,transparent 6px) 0 0,linear-gradient(to right,#000 6px,transparent 6px) 0 100%,linear-gradient(to left,#000 6px,transparent 6px) 100% 0,linear-gradient(to left,#000 6px,transparent 6px) 100% 100%,linear-gradient(to bottom,#000 6px,transparent 6px) 0 0,linear-gradient(to bottom,#000 6px,transparent 6px) 100% 0,linear-gradient(to top,#000 6px,transparent 6px) 0 100%,linear-gradient(to top,#000 6px,transparent 6px) 100% 100%;
							background-repeat: no-repeat;
							background-size: 10px 10px;
							padding: 11px 13px;
							display: inline-block;
							line-height: 23px;
							text-align: left;
							margin-bottom: 5px;
						}
						.uhb-c-sub-text {
							font-weight: 800;
						}
						&:before, &:after {
							content: " ";
							display: table;
							width: 92%;
							margin-left: 4%;
						}
						&:before {
							height: 20px;
							border-top: 1px solid #163726;
						}
						&:after {
							height: 15px;
							border-bottom: 1px solid #163726;
						}
					}
				}
			}
		}
	}
	&.onsticky {
		padding-top: 152px;
		.sticky {
			@include alltransition(1s);
			top: 0;
			clip-path: polygon(0% 0%, 100% 0, 100% calc(100% - 25px), 0% 100%);
			transform: translateZ(0);
			#understrap_carousel_store_home {
				.carousel-item {
					p, span {
						color: $black;
					}
				}
			}
		}
	}
	.navbar {
		@include alltransition(0.5s);
		.navbar-toggler {
			//Default
			color: rgba(0,0,0,.5);
	    	border-color: rgba(0,0,0,.5);
		    position: fixed;
		    z-index: 99999;
		    right: 35px;
		    top: 27px;
		    padding: 20px 0;
		    [class^="icon-"] {
	    		font-size: 81px;
	    		color: $green_lt;
				width: 50px;
				height: 50px;
				line-height: 50px;
				@media (min-width: 768px) {
					&:hover {
						color: $green_dk;
					}
				}
	    	}
	    	.icon-clear:before {
				position: relative;
				left: -15px;
	    	}
		    &.tk-burger {
		    	border-color: transparent;
		    	&:focus {
		    		border: none;
		    		box-shadow: none;
		    		outline: none;
		    	}
		    	img {
		    		@include alltransition(0.5s);
					display: none;
					width: 70px;
					@media (max-width: 767px) {
						width: 50px;
					}
					&.normal_nav_button {
						display: block;
					}
				}
				&:hover {
					@media (min-width: 768px) {
						img {
							@include alltransition(0.5s);
							&.normal_nav_hover_button {
								display: block;
							}
							&.normal_nav_button {
								display: none;
							}
						}
					}
				}
				&.open-nav {
					top: 25px;
					padding-right: 35px;
				}
		    }
	    	&:not(.collapsed) {
	    		[class^="icon-"] {
		    		display: block;
		    	}
	    		img {
	    			display: none!important;
	    		}
	    	}
		    &.collapsed {
		    	position: initial;
		    	[class^="icon-"] {
		    		display: none;
		    	}
		    }
	    	.navbar-toggler-icon {
	    		background-image: "data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(255, 255, 255, 0.5)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E";
	    	}
		}
	}
	.navbar-brand {
		img {
			@include alltransition(0.5s);
			display: none;
			&.normal_logo {
				display: block;
			}
		}
		&:hover {
			@media (min-width: 768px) {
				img {
					@include alltransition(0.5s);
					&.normal_hover_logo {
						display: block;
					}
					&.normal_logo {
						display: none;
					}
				}
			}
		}
	}
	&.derby-square, &.derby-square-post {
		background-color: $derby_black;
		.navbar-derby-square {
			width: 180px;
			filter: drop-shadow(0 0 10px #000);
		}
		.main-content {
			background-color: $derby_black;
		}
		&.single-post {
			#content {
				.entry-title {
					color: #fff;
				}
			}
			.store-wrapper p {
				color: #fff;
			}
		}
		//&:not(.onsticky) {
		//	#store-images-container {
		//		z-index: 99;
		//	}
		//}
		.store-wrapper {
			#content {
				.row.header-row {
					position: relative;
					padding-bottom: 10px;
					&:before {
						content: " ";
						//display: table;
						position: absolute;
						width: calc(100% - 50px);
						height: 30px;
						top: -19px;
						left: 16px;
						z-index: 1;
						background: linear-gradient(to right,#fff 14px,transparent 14px) 0 0,linear-gradient(to left,#fff 14px,transparent 14px) 100% 0,linear-gradient(to bottom,#fff 14px,transparent 14px) 0 0,linear-gradient(to bottom,#fff 14px,transparent 14px) 100% 0;
						background-repeat: no-repeat;
						background-size: 26px 26px;
					}
					&:after {
						content: " ";
						//display: table;
						position: absolute;
						width: calc(100% - 50px);
						height: 30px;
						bottom: 28px;
						left: 16px;
						z-index: 1;
						background: linear-gradient(to right,#000 14px,transparent 14px) 0 0,linear-gradient(to left,#fff 14px,transparent 14px) 100% 0,linear-gradient(to top,#000 14px,transparent 14px) 0 0,linear-gradient(to top,#fff 14px,transparent 14px) 100% 0;
						background-repeat: no-repeat;
						background-size: 26px 26px;
					}
				}
			}
		}
		.map-main-container.main-single-store-map {
			.col-left {
				.main-map {
					#interactive:before {
						height: 152px;
						top: -123px;
					}
				}
			}
		}
		&.post-template-single-store {
			#store-images-container {
				margin-top: -85px;
				.si-1 {
					height: 685px;
					clip-path: polygon(0% 0%, 100% 85px, 100% calc(100% - 25px), 0% 100%);
					transform: translateZ(0);
				}
				.si-bottom-wrap {
					clip-path: polygon(0 25px,100% 0,100% 100%,0 85%);
					transform: translateZ(0);
				}
			}
			.store_schedule_wrapper {
				clip-path: polygon(0 0,100% 0,100% 100%,0 calc(100% - 57px));
				transform: translateZ(0);
				padding-bottom: 60px;
			}
		}
		.store-wrapper .understrap_social_elements {
			a {
				border-color: #fff;
				color: #fff;
				&:hover {
					background-color: #454545;
				}
				&.social_instagram {
					background-image: none;
					i {
						opacity: 1;
						color: #fff !important;
					}
				}
			}
		}
		&.post-template-single-store {
			.pw-key-info {
				a {
					color: #fff !important;
				}
			}
			.store_schedule_wrapper {
				background-color: #454545;
				.store_schedule {
					.wd-div, .we-div {
						&.active {
							.ss-wraps {
								background-color: $derby_black;
							}
						}
					}
					.ss_weekdays {
						border-right-color: #fff;
					}
				}
			}

			.fs-wrap {
				background-color: $derby_black;
				#slider-footer-mailer_wrapper {
					background-color: $derby_black !important;
				}
				rs-module {
					.rs-layer {
						color: #fff !important;
					}
				}
			}
		}
		.wpcf7-form {
			input[type="text"], input[type="email"], input[type="url"] {
				border: 1px solid $derby_gray!important;
				background-color: $derby_gray;
				&::placeholder {
					color: #fff;
				}
			}
			.tk-checkbox {
				.wpcf7-list-item-label:before {
					background-color: $derby_gray;
				}
				input[type=checkbox]:checked+.wpcf7-list-item-label:before {
					background-color: $derby_gray;
				}
				.tkc-label {
					color: #fff;
				}
				//input[type=checkbox]:checked+.wpcf7-list-item-label {
				//	background-color: #000;
				//}
			}
			input.wpcf7-not-valid {
				background-color: #ee544f !important;
				color: #b03324 !important;
				border-color: #ee544f !important;
			}
			.wpcf7-mock-submit {
				background-color: #fff !important;
				color: #000 !important;
				&:hover {
					opacity: 0.8;
				}
			}
			small {
				color: #fff !important;
			}
		}
		#wrapper-footer {
			background-color: #000;
		}
		.map-main-container .col-left .main-map:after {
			background-color: $derby_black;

		}
		@media (max-width: 991px) {
			.last-col {
				padding-left: 0 !important;
				padding-right: 0 !important;
			}
		}
		@media (max-width: 575px) {
			.left-col {
				margin-bottom: 0 !important;
			}
		}
	}
	&.derby-square, &.derby-test, &.derby-square-post {
		.navbar {
			.navbar-toggler.tk-burger {
				&.open-nav img {
					display: none !important;
				}
			}
		}
	}
	&.derby-square, &.derby-test {
		.navbar-brand {
			img {
				filter: drop-shadow(2px 2px 5px #626262);
			}
		}
		.navbar .navbar-toggler.tk-burger {
			filter: drop-shadow(0 0 1px #000);
		}
		#understrap_carousel_store_home {
			.carousel-item {
				p, span {
					color: #fff;
					filter: drop-shadow(2px 2px 5px #626262);
				}
			}
		}
		#understrap-hero-banner {
			//background-image: url(/wp-content/uploads/2024/02/ttc_dark_bg-scaled.jpg) !important;
			#uhb-content {
				h3.uhb-c-main-text {
					text-transform: uppercase;
					font-size: 120px;
					font-weight: 800;
					text-align: left;
					width: fit-content;
					background: linear-gradient(to right, #fff 14px, transparent 14px) 0 0, linear-gradient(to right, #fff 14px, transparent 14px) 0 100%, linear-gradient(to left, #fff 14px, transparent 14px) 100% 0, linear-gradient(to left, #fff 14px, transparent 14px) 100% 100%, linear-gradient(to bottom, #fff 14px, transparent 14px) 0 0, linear-gradient(to bottom, #fff 14px, transparent 14px) 100% 0, linear-gradient(to top, #fff 14px, transparent 14px) 0 100%, linear-gradient(to top, #fff 14px, transparent 14px) 100% 100%;
					background-repeat: no-repeat;
					background-size: 26px 26px;
					padding: 22px 30px;
					@media (max-width: 850px) {
						font-size: 85px;
					}
					@media (max-width: 767px) {
						font-size: 60px;
						line-height: 55px;
						br {
							display: block !important;
						}
					}
				}
			}
		}
		.main-content {
			margin-top: -177px;
			#page-wrapper {
				padding-top: 890px;
				background-image: none !important;
			}
		}
		.derby-description-section {
			background-color: transparent;//$derby_black;
			clip-path: polygon(0 0, 100% 11%, 100% 81%, 0 94%);
			transform: translateZ(0);
			overflow: hidden;
			padding: 95px 0 140px;
			margin: -115px -30px 85px;
			.elementor-container {
				max-width: 1700px;
				.elementor-column {
					@media (max-width: 1270px) {
						&:first-child {
							width: 100%;
						}
						&:last-child {
							display: none;
						}
					}
				}
			}
			@media (max-width: 767px) {
				clip-path: polygon(0 0, 100% 11%, 100% 83%, 0 94%);
			}
			@media (max-width: 621px) {
				clip-path: polygon(0 0, 100% 11%, 100% 86%, 0 94%);
			}
		}
		.poly-white-bg {
			padding: 180px 24px !important;
			margin-top: -220px;
			.elementor-container {
				z-index: 1;
			}
			&:after {
				content: '';
				width: 100%;
				height: 100%;
				background-color: #fff;
				position: absolute;
				left: 0;
				bottom: 0;
				right: 0;
				z-index: 0;
				clip-path: polygon(0 10%,100% 0%,100% 100%,0% 86%);
				transform: translateZ(0);
			}
			@media (max-width: 767px) {
				padding: 170px 24px 290px !important;
				margin-top: -295px;
				&:after {
					clip-path: polygon(0 5%, 100% 0, 100% 100%, 0 95%);
					transform: translateZ(0);
				}
			}
		}
		.lush-entertainment {
			&:before {
				content: " ";
				//display: table;
				position: absolute;
				top: 0;
				bottom: 0;
				left: 0;
				background: linear-gradient(to right,#fff 14px,transparent 14px) 0 0,linear-gradient(to right,#fff 14px,transparent 14px) 0 100%,linear-gradient(to left,#000 14px,transparent 14px) 100% 0,linear-gradient(to left,#000 14px,transparent 14px) 100% 100%,linear-gradient(to bottom,#fff 14px,transparent 14px) 0 0,linear-gradient(to bottom,#000 14px,transparent 14px) 100% 0,linear-gradient(to top,#fff 14px,transparent 14px) 0 100%,linear-gradient(to top,#000 14px,transparent 14px) 100% 100%;
				background-repeat: no-repeat;
				background-size: 26px 26px;
				width: calc(100% - 50px);
				height: calc(100% - 180px);
				margin: 30px 26px;
				z-index: 1;
				@media (max-width: 767px) {
					top: -15px;
					height: calc(100% - 250px);
				}
			}
			.before-lush-entertainment-list {
				position: absolute;
				width: 50%;
				height: 50%;
				display: table;
				.inner-cont {
					display: table-cell;
					vertical-align: middle;
					padding: 100px;
					h3 {
						font-weight: 900;
						text-transform: uppercase;
						border-bottom: 8px solid #000;
						display: inline-block;
						font-size: 40px;
						line-height: 36px;
						padding-bottom: 5px;
						margin-bottom: 20px;
					}
				}
				@media (max-width: 767px) {
					height: 200px;
					width: 100%;
					position: relative;
					.inner-cont {
						padding: 25px 10px 50px;
						h3 {
							font-size: 36px;
							line-height: 34px;
						}
					}
				}
			}
			.elementor-posts-container:before {
				content: " ";
				display: block;
				position: relative;
				width: 100%;
				height: 100%;
				//@media (max-width: 767px) {
				//	padding-top: 200px;
				//}
			}
		}
		.poly-dark-bg {
			padding: 300px 26px 220px !important;
			margin-top: -200px;
			margin-bottom: -115px;
			.elementor-container {
				z-index: 1;
			}
			&:after {
				content: '';
				width: 100%;
				height: 100%;
				position: absolute;
				left: 0;
				bottom: 0;
				right: 0;
				z-index: 0;
				background-color: $derby_black;
				clip-path: polygon(0 0,100% 160px,100% 100%,0 100%);
				transform: translateZ(0);
			}
			&.tk-category-layout {
				article {
					.elementor-post__title {
						a {
							color: #fff !important;
						}
					}
					.elementor-post__excerpt {
						.post-excerpt {
							color: #fff !important;
						}
					}
					.elementor-post__read-more {
						color: #fff !important;
					}
					.understrap-open-hours {
						background-color: $derby_black;
						color: #fff;
					}
					//&:hover {
					//	.elementor-post__thumbnail__link {
					//		background-color: $derby_black;
					//	}
					//}
				}
			}
			@media (max-width: 767px) {
				padding: 245px 24px !important;
				margin-top: -295px;
			}
		}
		.fancy-food {
			&:before {
				content: " ";
				//display: table;
				position: absolute;
				top: 0;
				bottom: 0;
				left: 0;
				background: linear-gradient(to right,#fff 14px,transparent 14px) 0 0,linear-gradient(to right,#fff 14px,transparent 14px) 0 100%,linear-gradient(to left,#000 14px,transparent 14px) 100% 0,linear-gradient(to left,#fff 14px,transparent 14px) 100% 100%,linear-gradient(to bottom,#fff 14px,transparent 14px) 0 0,linear-gradient(to bottom,#000 14px,transparent 14px) 100% 0,linear-gradient(to top,#fff 14px,transparent 14px) 0 100%,linear-gradient(to top,#fff 14px,transparent 14px) 100% 100%;
				background-repeat: no-repeat;
				background-size: 26px 26px;
				width: calc(100% - 50px);
				height: calc(100% - 290px);
				margin: 110px 24px;
				z-index: 1;
			}
			.before-fancy-food-list {
				position: absolute;
				width: 50%;
				height: calc(50% - 155px);
				display: table;
				.inner-cont {
					display: table-cell;
					vertical-align: middle;
					padding: 100px;
					@media (max-width: 767px) {
						padding: 100px 20px;
					}
					h3 {
						color: #fff;
						font-weight: 900;
						text-transform: uppercase;
						border-bottom: 8px solid #dee2e6;
						display: inline-block;
						font-size: 40px;
						line-height: 36px;
						padding-bottom: 5px;
						margin-bottom: 20px;
					}
					p {
						color: #fff;
					}
				}
				@media (max-width: 767px) {
					height: 200px;
					width: 100%;
					.inner-cont {
						padding: 25px 30px 50px;
						h3 {
							font-size: 36px;
							line-height: 34px;
						}
					}
				}
			}
			.after-fancy-food-list {
				display: table;
				width: 100%;
				margin-top: 70px;
				.inner-cont {
					display: table;
					margin: 0 auto;
					text-align: center;
					h3 {
						font-size: 34px;
						line-height: 31px;
						text-transform: uppercase;
						font-weight: 800;
						color: #fff;
						display: table;
						&:after {
							content: " ";
							display: block;
							width: 60%;
							border-bottom: 8px solid #fff;
							margin: 12px auto 0;
						}
					}
					a {
						background: #fff;
						color: $derby_black;
						padding: 10px 75px;
						margin-top: 15px;
						display: inline-block;
						font-weight: 800;
						font-size: 20px;
						&:hover {
							opacity: 0.8;
						}
					}
				}
			}
			.elementor-posts-container:before {
				content: " ";
				display: block;
				position: relative;
				width: 100%;
				height: 100%;
				@media (max-width: 767px) {
					padding-top: 300px;
				}
			}
		}
	}
	&.home {
		.main-content {
			margin-top: -177px;
			#page-wrapper {
				padding-top: 860px;
			}
		}
	}
	&.home, &.derby-square:not(.derby-square-post), &.derby-test {
		.navbar-brand {
			img {
				@include alltransition(0.5s);
				&.reverse_logo {
					display: block;
				}
				&.normal_logo {
					display: none;
				}
			}
			&:hover {
				@media (min-width: 768px) {
					img {
						@include alltransition(0.5s);
						&.reverse_logo, &.normal_hover_logo {
							display: none;
						}
						&.reverse_hover_logo {
							display: block;
						}
					}
				}
			}
		}
		.navbar-toggler.tk-burger {
			img {
				@include alltransition(0.5s);
				&.reverse_nav_button {
					display: block;
				}
				&.normal_nav_button {
					display: none;
				}
				&.gray_nav_button {
					display: none;
				}
			}
			&:hover {
				@media (min-width: 768px) {
					img {
						@include alltransition(0.5s);
						&.reverse_nav_button, &.normal_nav_hover_button, &.gray_nav_button {
							display: none;
						}
						&.reverse_nav_hover_button {
							display: block;
						}
					}
				}

			}
		}
		.sticky {
			.navbar-brand {
				img {
					@include alltransition(0.5s);
					&.reverse_logo {
						display: none;
					}
					&.normal_logo {
						display: block;
					}
				}
				&:hover {
					@media (min-width: 768px) {
						img {
							&.reverse_logo, &.reverse_hover_logo, &.normal_logo {
								display: none;
							}
							&.normal_hover_logo {
								display: block;
							}
						}
					}
				}
			}
			.navbar-toggler.tk-burger {
				img {
					@include alltransition(0.5s);
					&.reverse_nav_button {
						display: none;
					}
					&.normal_nav_button {
						display: block;
					}
					&.gray_nav_button {
						display: none;
					}
				}
				&:hover {
					@media (min-width: 768px) {
						img {
							@include alltransition(0.5s);
							&.reverse_nav_button, &.reverse_nav_hover_button, &.normal_nav_button, &.gray_nav_button {
								display: none;
							}
							&.normal_nav_hover_button {
								display: block;
							}
						}
					}
				}
			}
		}
	}

	&.derby-square-post {
		.navbar-derby-square {
			img {
				transition: 0.5s filter;
			}
		}
		&.onsticky {
			.navbar-derby-square {
				filter: none;
				img {
					filter: invert(1) !important;
				}
			}
		}
		.navbar-brand {
			width: 120px;
			margin-right: 18%;
			@media (max-width: 992px) {
				display: none;
			}
			img {
				@include alltransition(0.5s);
				&.reverse_logo {
					display: block;
				}
				&.normal_logo {
					display: none;
				}
			}
			&:hover {
				@media (min-width: 768px) {
					img {
						@include alltransition(0.5s);
						&.reverse_logo, &.normal_hover_logo {
							display: none;
						}
						&.reverse_hover_logo {
							display: block;
						}
					}
				}
			}
		}
		.navbar-toggler.tk-burger {
			img {
				@include alltransition(0.5s);
				&:not(.gray_nav_button) {
					display: none !important;
				}
				&.gray_nav_button {
					display: block !important;
				}
			}
			&:hover {
				@media (min-width: 768px) {
					img {
						@include alltransition(0.5s);
						&:not(.gray_nav_hover_button) {
							display: none !important;
						}
						&.gray_nav_hover_button {
							display: block !important;
						}
					}
				}

			}
		}
		.sticky {
			.navbar-brand {
				img {
					@include alltransition(0.5s);
					&.reverse_logo {
						display: none;
					}
					&.normal_logo {
						display: block;
					}
				}
				&:hover {
					@media (min-width: 768px) {
						img {
							&.reverse_logo, &.reverse_hover_logo, &.normal_logo {
								display: none;
							}
							&.normal_hover_logo {
								display: block;
							}
						}
					}
				}
			}
			.navbar-toggler.tk-burger {
				img {
					@include alltransition(0.5s);
					display: none;
					&.gray_nav_button {
						display: block;
					}
				}
				&:hover {
					@media (min-width: 768px) {
						img {
							@include alltransition(0.5s);
							display: none;
							&.gray_nav_hover_button {
								display: block;
							}
						}
					}
				}
			}
		}
	}

	.navbar-brand {
		width: 190px;
		display: inline-block;
		@media (max-width: 767px) {
			width: 140px;
		}
	}

	#main-nav {
		padding: 38px 47px;
		.container, .container-fluid {
		    margin: 0px;
		    width: 100%;
		    max-width: 100%;
		    padding: 0px;
		}
		@media (max-width: 767px) {
			padding: 30px;
		}
	}


	/*FOOTER STYLES*/
	#wrapper-footer {
		clip-path: polygon(0% 70px, 100% 0%, 100% 100%, 0% 100%);
		transform: translateZ(0);
		color: #fff;
		background-color: $green_dk;
		padding: 38px 47px;
		@media (max-width: 767px) {
			text-align: center;
		}
		.container, .container-fluid {
		    margin: 0;
		    width: 100%;
		    max-width: 100%;
		    padding: 0;
		    padding-top: 80px;
			#footer-logo {
				display: block;
				width: 182px;
				margin-bottom: 10px;
				@media (max-width: 767px) {
					margin: 0 auto 30px;
				}
			}
			.site-footer {
				.row {
					display: flex;
					align-items: flex-end;
					[class^="col-"] {
						@media (max-width: 767px) {
							&:after {
								content: '';
								height: 2px;
								width: 50%;
								display: block;
								background-color: $yellow_lt;
								margin: 55px auto;
							}
							&:nth-child(1) {
								order: 1;
							}
							&:nth-child(2) {
								order: 3;
								&:after {
									display: none;
								}
							}
							&:nth-child(3) {
								order: 2;
							}
						}
					}
				}
				#footer_text-div {
					line-height: initial;
    				width: 194px;
    				@media (max-width: 767px) {
						width: 100%;
						text-align: center;
					}
				}
				#footer_location-div, #footer_extra-div, #footer_copyright-div {
					line-height: initial;
					text-align: right;
				}
				#footer_copyright-div {
					text-align: center;
					position: relative;
				}
				#footer_location-div {
					padding-right: 48px;
					@media (max-width: 767px) {
						text-align: center;
						padding-top: 63px;
						padding-right: 0;
					}
				}
				#footer_location-div:before {
					content: '';
					width: 40px;
					height: 50px;
					background-image: url('../images/location-pin.svg');
					background-position: center;
					background-size: contain;
					position: absolute;
					top: 4px;
					right: 15px;
					background-repeat: no-repeat;
					@media (max-width: 767px) {
						-webkit-transform: translateX(-50%) translateY(0);
						-ms-transform: translateX(-50%) translateY(0);
						transform: translateX(-50%) translateY(0);
						right: initial;
						top: 0;
						left: 50%;
					}
				}
				#footer_location-div,
				#footer_extra-div {
					a {
						color: #fff;
						&:hover {
							color: $yellow_lt;
							text-decoration: underline;
						}
					}
				}
				#footer_extra-div {
					margin-top: 30px;
					@media (max-width: 767px) {
						margin-top: 0;
						text-align: center;
						&:before {
							content: '';
							height: 2px;
							width: 50%;
							display: block;
							background-color: $yellow_lt;
							margin: 55px auto;
						}
					}
				}
			}
		}
	}
	.understrap_carousel {
		.carousel-inner {
			min-height: 180px;
		}
		.carousel-item {
			height: 100%;
		}
		.carousel-caption {
			color: $black;
			width: 90%;
			left: 50%;
			-webkit-transform: translateX(-50%) translateY(0);
			-ms-transform: translateX(-50%) translateY(0);
			transform: translateX(-50%) translateY(0);
			top: 0;
			padding: 0;
			h3, p {
				margin: 0;
			}
			h3 {
				color: $black;
				a {
					color: $black;
				}
			}
		}
		.carousel-indicators {
			li {
				background-color: $grey_dk;
			}
			&.ci-image {
				align-items: center;
				position: relative;
				li {
					border: 10px solid $grey;
					width: 70px;
					height: 70px;
					overflow: hidden;
					background-color: #fff;
					background-size: cover;
					background-repeat: no-repeat;
					background-position: center;
					img {
						width: 100%;
						height: 100%;
						display: block;
					}
					&:hover {
						border-color: $yellow_lt;
					}
					&.active {
						width: 90px;
						height: 90px;
						border-color: $yellow_lt;
					}
					&.wrapped {
						img {
							opacity: 0;
						}
					}
				}
			}
		}
	}

	.elementor-section {
		&.elementor-section-boxed {
			&> .elementor-container {
				max-width: 1400px;
				margin-top: -50px;
				&.elementor-column-gap-custom {
					@media (min-width: 768px) {
						display: table;
						width: 100%;
						height: 100%;
						padding: 0 45px;
						.elementor-column {
							display: table-cell;
							vertical-align: middle;
						}
					}
				}
			}
			@media (min-width: 992px) {
				&.full-width-map {
					& > .elementor-container {
						max-width: 100%;
						padding: 0 15px 0 80px;
					}
				}
			}
		}
	}

	.understrap-img-and-post {
		.elementor-container {
			.elementor-column:nth-child(1) {
				position: absolute;
				width: auto;
				top: 50%;
				-webkit-transform: translateX(0) translateY(-50%);
				-ms-transform: translateX(0) translateY(-50%);
				transform: translateX(0) translateY(-50%);
				z-index: 1;
				.elementor-widget-wrap {
					padding: 0!important;
				}
			}
			.elementor-column:nth-child(2) {
				width: 100%;
				padding-left: 110px;
				z-index: 0;
			}
			.elementor-widget-container {
				.elementor-text-editor {
					p:last-child {
						margin: 0;
					}
				}
			}
		}
	}
	.welcomed-bg-grey-polygon:before {
		$wbg_width: calc(100% + 1024px);
		$wbg_height: calc(100% - 150px);
		$wbg_poly: polygon(0 0,100% 50px,100% calc(100% - 120px),0 100%);
		transform: translateZ(0);
		@include poly_top($wbg_width, $wbg_height, $poly_gray, $wbg_poly, -1, -28px);
		@media (max-width: 767px) {
			$wbg_poly: polygon(0 0,100% 43px,100% calc(100% - 124px),0 100%);
			width: 100%;
			@include poly_top($wbg_width, $wbg_height, $poly_gray, $wbg_poly, -1, -28px);
		}
	}
	.eat-bg-grey-polygon:after {
		$ebg_width: calc(100% + 1024px);
		$ebg_height: 50%;
		$ebg_poly: polygon(0% 124px,100% 0%,100% 100%,0% 100%);
		transform: translateZ(0);
		@include poly_bottom($ebg_width, $ebg_height, $poly_gray, $ebg_poly, -1, -1px);
		@media (max-width: 767px) {
			$ebg_poly: polygon(0% 30px,100% 0%,100% 100%,0% 100%);
			$ebg_height: 100%;
			width: 100%;
			@include poly_bottom($ebg_width, $ebg_height, $poly_gray, $ebg_poly, -1, -1px);
		}
	}
	.shop-z-index-3 {
		.elementor-container {
			z-index: 3;
		}
		&:after {
			z-index: 2;
		}
	}
	@media (max-width: 767px) {
		.elementor-5 {
			.elementor-element {
				&.elementor-element-e6ff794 {
					.elementor-container {
						z-index: 3;
						padding-bottom: 100px;
					}
				}
				&.elementor-element-6261b90 {
					padding-top: 150px!important;
					margin-top: -50px!important;
				}
			}
		}
	}
	.derby-bg-black-polygon {
		margin-top: -395px;
		margin-bottom: -120px;
		padding-top: 420px!important;
		z-index: 2;

		&:after {
			content: '';
			width: 100%;
			height: 100%;
			background-color: $derby_black;
			position: absolute;
			top: 0;
			left: 0;
			clip-path: polygon(0% 8%,100% 0%,100% 93%,0% 100%);
			transform: translateZ(0);
			z-index: 1;
		}
		.elementor-container {
			z-index: 3;
		}
		.cat-img-header2 {
			.elementor-image {
				img {
					height: 200px;
				}
			}
		}
		@media (min-width: 768px) {
			padding-bottom: 100px!important;
		}
		@media (max-width: 767px) {
			margin-bottom: 40px!important;
			padding-bottom: 35px!important;
			background: #EAE5E0;
			.cat-img-header5 .elementor-image img {
				height: 120px;
			}
			.understrap_carousel .carousel-caption h3 a {
				color: #fff!important;
			}
		}
		p, span, a:not(.elementor-cta) {
			color: #fff;
		}
		.elementor-widget-text-editor {
			h3 {
				color: #fff;
				font-size: 38px;
				font-weight: 800;
				line-height: 38px;
				border-bottom: 10px solid #fff;
				padding-bottom: 10px;
				width: 85%;
				@media (max-width: 767px) {
					margin: 45px auto 55px;
				}
			}
		}
		a.elementor-cta {
			.elementor-cta__content {
				background-color: #fff !important;
				h2 {
					color: #353333 !important;
				}
			}
			&:hover {
				opacity: 0.8;
			}
		}
		.tk-cta {
			&:hover {
				.elementor-cta {
					.elementor-cta__content {
						background-color: #fff !important;
					}
				}
			}
		}
		.understrap-basic-cat-list {
			.ubcl-ul {
				.ubcl-item {
					.ubcli-content {
						h3 a, a {
							color: #fff;
						}
					}
				}
			}
		}
	}

	.shop-bg-grey-polygon-top:before {
		$sbgt_width: calc(100% + 1024px);
		$sbgt_height: 250px;
		$sbgt_poly: polygon(0% 0%,100% 0%,100% 100%,0% calc(100% - 250px));
		transform: translateZ(0);
		@include poly_top($sbgt_width, $sbgt_height, $poly_gray, $sbgt_poly, -1);
		@media (max-width: 767px) {
			width: 100%;
			$sbgt_height: 100%;
			$sbgt_poly: polygon(0% 0%,100% 0%,100% 100%,0% 100%);
			@include poly_top($sbgt_width, $sbgt_height, $poly_gray, $sbgt_poly, -1);
		}
	}

	.shop-bg-grey-polygon-bottom:after {
		$sbg_width: calc(100% + 1024px);
		$sbg_height: calc(280px + 115px);
		$sbg_poly: polygon(0 0,100% 75px,100% 100%,0 100%);
		transform: translateZ(0);
		@include poly_bottom($sbg_width, $sbg_height, $poly_gray, $sbg_poly, -1, -1px);
		@media (max-width: 767px) {
			width: 100%;
		}
	}

	.poly-yellow-bottom > .elementor-container:first-child {
		z-index: 1;
	}

	.poly-yellow-bottom:after {
		$pyb_width: calc(100% + 1024px);
		$pyb_height: calc(155px + 115px);
		$pyb_poly: polygon(calc(100% - 150px) 0%,100% 0,100% 100%,0% 100%);
		transform: translateZ(0);
		@include poly_bottom($pyb_width, $pyb_height, $yellow_lt, $pyb_poly, 0);
		@media (max-width: 767px) {
			width: 100%;
		}
	}

	.poly-gray-bottom:after {
		content: '';
		width: 100%;
		height: 100%;
		background-color: $poly_gray;
		position: absolute;
		left: 0;
		bottom: 0;
		z-index: -1;
	}
	.derby-description-section {
		background-color: $derby_black;
		overflow: hidden;
		margin: -140px -30px 0;
		clip-path: polygon(0 10%,100% 0%,100% 90%,0% 100%);
		transform: translateZ(0);
		padding: 160px 0 94px;
		z-index: 1;
		&:before {
			content: " ";
			//display: table;
			position: absolute;
			width: calc(100% - 46px);
			height: calc(100% - 305px);
			top: 92px;
			bottom: 0;
			left: 0;
			background: linear-gradient(to right,#fff 14px,transparent 14px) 0 0,linear-gradient(to right,#fff 14px,transparent 14px) 0 100%,linear-gradient(to left,#fff 14px,transparent 14px) 100% 0,linear-gradient(to left,#fff 14px,transparent 14px) 100% 100%,linear-gradient(to bottom,#fff 14px,transparent 14px) 0 0,linear-gradient(to bottom,#fff 14px,transparent 14px) 100% 0,linear-gradient(to top,#fff 14px,transparent 14px) 0 100%,linear-gradient(to top,#fff 14px,transparent 14px) 100% 100%;
			background-repeat: no-repeat;
			background-size: 26px 26px;
			margin: 25px;
		}
		.elementor-background-overlay {
			background-color: transparent !important;
		}
		.elementor-container {
			padding: 50px;
			.elementor-column {
				&:first-child {
					padding: 10px;
					.elementor-widget-wrap {
						display: table;
						.elementor-widget {
							vertical-align: middle;
							display: table-cell;
							&.derby-logo {
								img {
									width: 300px;
								}
								@media (max-width: 991px) {
									padding-bottom: 70px;
								}
							}
						}
					}
				}

				&:last-child {
					padding-top: 0;
					.elementor-widget-wrap {
						.elementor-widget {
							&.derby-logo-desc {
								padding-right: 100px;
								@media (max-width: 991px) {
									padding-right: 0;
								}
							}
						}
						a {
							background: #fff;
							color: #353333;
							padding: 15px 15px;
							margin-top: 15px;
							display: inline-block;
							font-weight: 800;
							font-size: 20px;
							&:hover {
								opacity: 0.8;
							}
						}
						.elementor-widget-image {
							.elementor-widget-container {
								.elementor-image {
									img {
										width: 100%;
									}
								}
							}
						}
					}
				}
				.elementor-widget-text-editor {
					margin-bottom: 0;
				}
				@media (max-width: 991px) {
					&.elementor-col-50 {
						width: 100%;
					}
				}
			}
		}
		h3 {
			color: #fff;
			font-size: 48px;
			font-weight: 800;
			line-height: 46px;
			border-bottom: 10px solid #fff;
			padding-bottom: 10px;
		}
		p {
			color: #fff;
			padding-top: 40px;
			margin-bottom: 0;
		}
		@media (max-width: 767px) {
			margin-top: -274px;
			padding-top: 135px;
			padding-bottom: 64px;
			clip-path: polygon(0 6%,100% 0,100% 94%,0 100%);
			transform: translateZ(0);
			margin-bottom: 160px!important;

			h3 {
				font-size: 32px;
				line-height: 36px;
			}
		}
	}

	.tk-fullwidth {
		padding-right: 15px;
		padding-left: 15px;
		margin-right: -15px;
		margin-left: -15px;
	}

	//.elementor-container {
	//	.elementor-column {
    //    	.elementor-widget-wrap {
	//			height: 100%;
	//			.elementor-element {
	//				&.poly-featured-image {
	//					height: 100%;
	//					.elementor-widget-container {
	//						height: 100%;
	//						.elementor-image {
	//							height: 100%;
	//							img {
	//								height: 100%;
	//								object-fit: cover;
	//							}
	//						}
	//					}
	//				}
	//			}
	//		}
	//	}
	//}

	.news-section-bg {
		background-color: $poly_gray;
		&.on-mobile {
			@media (max-width: 767px) {
				.elementor-heading-title {
					font-size: 45px!important;
				}
			}
		}
		.elementor-container {
			z-index: 1;
		}
		&.position-yellow-bottom:before {
			$nsb_width: calc(100% + 30px);
			$nsb_height: calc(100% + 60px);
			$nsb_poly: polygon(0px 0px, 100% 0px, 100.00% 82%, 0px 100%);
			transform: translateZ(0);
			@include poly_top($nsb_width, $nsb_height, $poly_gray, $nsb_poly, -1, 0);
			top: -60px;
		}
		&.position-yellow-bottom:after {
			$pyb_width: calc(100% + 1024px);
			$pyb_height: calc(215px + 115px);
			$pyb_poly: polygon(calc(100% - 150px) 0%,100% 0,100% 100%,0% 100%);
			transform: translateZ(0);
			@include poly_bottom($pyb_width, $pyb_height, $yellow_lt, $pyb_poly, 0, 0);
			@media (max-width: 767px) {
				// width: 100%;
				$pyb_poly: polygon(calc(100% - 40px) 0%,100% 0,100% 100%,0% 100%);
				@include poly_bottom($pyb_width, $pyb_height, $yellow_lt, $pyb_poly, 0, 0);
			}
		}
	}

	.tk-expand-btn {
		color: #fff;
		text-decoration: none;
		display: inline-flex;
		align-items: center;
		overflow: hidden;
		.tk-eb-img {
			// width: 100px;
			// height: 100px;
			padding: 20px 25px 20px 20px;
			background-color: $green_lt;
			clip-path: polygon(0 0,100% 0,calc(100% - 5px) 100%,0 100%);
			transform: translateZ(0);
			img {
				height: 55px;
			}
		}
		.tk-eb-txt {
			// height: 55px;
			font-size: 45px;
			display: block;
			align-items: center;
			font-weight: bold;
			padding: 20px 20px 20px 25px;
			margin-left: 8px;
			color: #fff;
			clip-path: polygon(5px 0,100% 0,100% 100%,0 100%);
			transform: translateZ(0);
			position: relative;
			opacity: 0;
			right: 100%;
			z-index: -1;
			@include alltransition(.5s);
			.tk-ebt-wrap {
				height: 55px;
				display: block;
				text-overflow: ellipsis;
				line-height: initial;
				overflow: hidden;
			}
		}
		&:hover {
			.tk-eb-img, .tk-eb-txt {
				background-color: $green_gray;
			}
			.tk-eb-txt {
				opacity: 1;
				right: 0;
				transform: translate(0, 0);
			}

		}
		&:focus, &:clicked, &:active {
			.tk-eb-img, .tk-eb-txt {
				background-color: $green_dk;
			}
		}
	}

	.tk-news-layout, .tk-category-layout {
		$thumb_height: 493px;
		article {
			position: relative;
			.elementor-post__thumbnail {
				height: $thumb_height;
			}
			.understrap-read-more-link {
				color: #fff;
				position: absolute;
				width: 100%;
				height: 100%;
				top: 0;
				left: 0;
				display: inline-flex;
				align-items: center;
				text-align: center;
				background-color: transparent;
			    border: none;
			    justify-content: center;
			    font-size: 30px;
			    font-weight: bold;
			    opacity: 0;
				@include alltransition(.5s);
			}
			.elementor-post__thumbnail__link {
				clip-path: polygon(0 0,100% 3%,100% 100%,0 94%);
				transform: translateZ(0);
				overflow: hidden;
				@include alltransition(.5s);
			}
			.elementor-post__thumbnail {
				background-color: #fff;
				background-size: cover;
				background-repeat: no-repeat;
				background-position: center;
				&.wrapped {
					img {
						display: none;
					}
				}
				@include alltransition(.5s);
			}
			.elementor-post__text {
				.elementor-post__title{
					padding-right: 20%;
					a {
						font-size: 34px!important;
						line-height: 34px;
						font-family: Gilroy;
						font-weight: 800;
					}
				}
			}
			.elementor-post__read-more {
				color: $green_dk!important;
				font-family: 'Gilroy', sans-serif!important;
				font-size: 15px!important;
				font-weight: 800;
			}
			.elementor-post__title {
				a {
					color: $green_dk;
				}
			}
			.elementor-post__excerpt {
				padding-top: 15px;
				p, .post-excerpt {
					color: $black!important;
					font-size: 16px!important;
					text-decoration: none;
					line-height: 20px;
				}
			}
		}
	}

	.tk-news-layout {
		article {
			.elementor-post__thumbnail__link {
				margin-bottom: -1px!important;
			}
			.elementor-post__text {
				.elementor-post__title {
					margin-bottom: 0px;
					a {
						font-size: 40px!important;
						font-weight: 800 !important;
						line-height: 40px!important;
					}

				}
			}
			.elementor-post__excerpt {
				padding-top: 0px!important;
				a.post-excerpt {
					font-family: 'Helvetica', sans-serif;
					font-size: 14px!important;
					color: $para_dark!important;
				}
			}
			.elementor-post__read-more {
				color: $black!important;
			}
		}
	}

	&.home {
		.tk-news-layout {
			background-color: transparent;
			padding-right: 0;
			.elementor-widget-container {
				margin-right: -15px;
				@media (max-width: 767px) {
					margin-right: 0;
				}
			}
		}
	}

	.tk-category-layout {
		article {
			clip-path: polygon(0 0,100% 13px,100% 100%,0 100%);
			transform: translateZ(0);
			overflow: hidden;
			.elementor-post__thumbnail, .understrap-read-more-link {
				height: 325px;
				overflow: hidden;
			}
			.understrap-open-hours {
				position: absolute;
				top: 5px;
				right: 20px;
				padding: 10px;
				background-color: $poly_gray;
				clip-path: polygon(0 0, 100% 0, 100% calc(100% - 5px), 0% 100%);
				transform: translateZ(0);
			}
			.elementor-post__title {
				a {
					color: #000;
					font-weight: 800;
				}
			}
		}
	}

	.elementor-posts-masonry.elementor-grid {
		grid-auto-rows: 1fr;
		.elementor-grid-item {
			margin: 0!important;
		}
	}

	.wpcf7-form, .mc4wp-form {
		font-family: 'Gilroy',  sans-serif;
		color: #000;
		p {
			margin-bottom: 8px;
			color: #000;
			position: relative;
		}
		br {
			display: none;
		}
		small {
			color: #000;
		}
		.wpcf7-response-output {
			background-color: $error_red;
			color: #fff;
			margin: 32px 0px 16px;
			padding: 10px;
			color: #fff;
			border: none;
		}
		input[type="text"], input[type="email"], input[type="url"] {
			height: 64px;
			border: 1px solid $poly_gray!important;
			border-radius: 0!important;
			font-weight: 300;
			font-family: 'Gilroy', sans-serif;
			font-size: 30px;
			padding: 1px 20px;
			width: 100%;
			background-color: $poly_gray;
			&:focus {
				outline: none!important;
				border-color: $green_lt!important;
				box-shadow: none!important;
			}
		}
		input[type="submit"], .wpcf7-mock-submit {
			font-family: 'Gilroy', sans-serif;
			color: #fff;
			font-weight: 800!important;
	    width: 100%;
	    text-align: center!important;
	    border: none;
	    padding: 28px 0px!important;
	    font-size: 30px;
	    background-color: $green_lt;
	    box-sizing: content-box;
  		line-height: 35px!important;
  		border-radius: 0!important;
  		clip-path: polygon(0 0, 100% 0, 100% 100%, 0% 88%);
		transform: translateZ(0);
  		text-decoration: none;
	    &:focus, &:hover {
	    	background-color: $green_dk;
	    }
		}
		.wpcf7-mock-submit {
			display: none;
			width: 100%;
			padding: 40px 0!important;
		}
		input.wpcf7-not-valid {
			background-color: $error_red;
			color: #b03324;
			border-color: $error_red!important;
		}
		.ajax-loader {
			display: none;
		}
		.wpcf7-not-valid-tip {
			margin: 6px 0px -6px 0px;
		}
		.sf-small {
			margin-top: -15px;
		}
		&.invalid {
			.wpcf7-mock-submit {
				display: block;
			}
			input[type="submit"] {
				display: none;
			}
		}
		.tk-checkbox {
			cursor: pointer;
			position: relative;
			input[type="checkbox"] {
				opacity: 0;
				z-index: 0;
				position: absolute;
			}
			.tkc-label:before, .wpcf7-list-item-label:before {
				content: '';
				position: absolute;
				height: 64px;
				width: 108px;
				background-color: #fff;
				color: #fff;
				left: -116px;
			    background-size: 49px;
			    background-position: center;
			    background-repeat: no-repeat;
			    top: 0;
			}
			.tkc-label, .wpcf7-list-item-label {
				font-weight: 400;
				font-size: 19px;
				display: block;
				margin-left: 116px!important;
				font-family: 'Gilroy';
				line-height: 20px!important;
				height: 64px;
				box-sizing: border-box;
				padding-top: 11px!important;
				padding-left: 10px;
				position: relative;
				color: #000;
				z-index: 1;
				span, strong {
					line-height: 16px!important;
				}
				@media (max-width: 700px) {
					font-size: 13px;
				}
				@media (max-width: 425px) {
					font-size: 15px;
				}
				@media (max-width: 320px) {
					font-size: 11px;
				}
			}
			input[type="checkbox"]:checked + .tkc-label:before, input[type="checkbox"]:checked + .wpcf7-list-item-label:before {
				background-color: $green_dk;
				background-image: url('../images/tick.svg');
			}

			&.green {
				.tkc-label, .wpcf7-list-item-label:before {
					background-color: $green_dk;
					color: #fff;
				}
			}

			.wpcf7-form-control-wrap + .tkc-label {
				position: absolute;
				z-index: 0;
				top: 0;
			}

			.wpcf7-list-item-label {
				color: transparent;
			}

			.wpcf7-list-item {
				height: 64px;
				margin: 0px;
				width: 100%;
			}

			.wpcf7-not-valid {
				.tkc-label:before, .wpcf7-list-item-label:before {
					background-color: $error_red;
				}
			}

		}
	}

	.bottom-yellow:after {
		content: '';
		width: calc(100% + 1024px);
		height: 50%;
		background-color: $yellow_lt;
		position: absolute;
		left: 50%;
		bottom: -200px;
		-webkit-transform: translateX(-50%) translateY(0);
		-ms-transform: translateX(-50%) translateY(0);
		transform: translateX(-50%) translateY(0);
		z-index: -1;
	}

	.elementor-cta {
		text-decoration: none;
	}

	#sf-success {
	    position: fixed;
	    width: 100%;
	    height: 100%;
	    background-color: $yellow_lt;
	    left: 0;
	    top: 0;
	    z-index: 99;
	    text-align: center;
	    display: none;
	    align-items: center;
	    flex-flow: column;
	    justify-content: center;
	    display: none;
	    h3, p {
	    	width: 100%;
	    	text-align: center;
	    	padding: 0 15px;
	    }
	    h3 {
	    	font-weight: 800;
	    	font-size: 50px;
	    	color: $green_dk;
	    }
	    p {
	    	@media (min-width: 768px) {
	    		width: 50%;
	    	}
	    	@media (max-width: 767px) {
	    		width: 100%;
	    	}
			font-size: 18px;
			color: #000;
	    }
	    &.show {
	    	display: flex;
	    }
	}

	#subscribe-form {
		p {
			margin-bottom: 8px;
		}
		#sf-success {
			display: none;
		}
	}

	.cat-header-set {
		margin: 0 -15px;
	}
	&.page-template-category-page {
		// overflow: hidden;
	}
	&.page-template-category-page:before {
		// $ptc_width: calc(100% + 1024px);
		// $ptc_height: 960px;
		// $ptc_poly: polygon(0% 150px,100% 0,100% calc(100% - 150px),0% 100%);
		// @include poly_top($ptc_width, $ptc_height, $poly_gray, $ptc_poly, -1, 180px);
		// @media (max-width: 767px) {
		// 	width: 100%;
		// }
	}

	.fs-wrap {
		padding-top: 168px;
		padding-bottom: 85px;

		@media (max-width: 991px) {
			padding-top: 85px;
		}

		&.yellow {
			background-color: $yellow_lt;
			clip-path: polygon(0 13%,100% 0,100% 94%,0% 100%);
			transform: translateZ(0);
			overflow: hidden;
    		margin-bottom: -65px;
    		margin-top: -100px;
			@media (max-width: 991px) {
				clip-path: polygon(0 6%,100% 0,100% 91%,0% 100%);
				margin-top: 0;
			}
		}
	}

	.understrap_opening_carousel {
		background-color: $green_dirt;
		color: $black;
		padding: 20px;
		.carousel-inner {
			z-index: 1;
		}
		.carousel-item {
			min-height: 220px;
			padding: 0;
			.carousel-caption {
				position: relative;
				left: 0;
				h3 {
					text-align: left;
					a {
						font-size: 30px;
						font-weight: 700;
					}
				}
				a {
					color: $black;
				}
				.uoc-hours {
					text-align: left;
					padding: 20px;
					font-size: 18px;
					* {
						color: $black;
					}
					strong {
						display: block;
						margin-top: 20px;
					}
					strong:first-child {
						margin-top: 0;
					}
				}
			}
		}
		.carousel-control-prev, .carousel-control-next {
	    width: 25px;
	    height: 35px;
	    left: auto;
	    top: 19px;
	    color: $black;
	    z-index: 2;
		}
		.carousel-control-prev {
			right: 35px;
		}
		.carousel-control-next {
			right: 10px;
		}
	}
	.pow-featured-img {
		height: 1018px;
		background-color: $grey;
		background-size: cover;
		background-position: center center;
		background-repeat: no-repeat;
		@media (max-width: 767px) {
			clip-path: polygon(0% 30px, 100% 0%, 100% 100%,0% calc(100% - 40px))!important;
			transform: translateZ(0);
			height: calc(1018px / 2);
		}
		img {
			display: none;
		}
	}

	&.loaded-nav {
		#wrapper-navbar + section {
			display: none!important;
		}
	}
	&.single-post {
		p {
			color: $black;
		}
		.right-col {
			display: flex;
			flex-flow: column;
			justify-content: center!important;
		}
		#content {
			padding-right: 45px;
			padding-left: 45px;
			@media (max-width: 767px) {
				padding-right: 15px;
				padding-left: 15px;
			}
			.entry-title {
				font-size: 100px;
				font-weight: 900;
				line-height: 100px;
				color: $green_dk;
				@media (max-width: 767px) {
					font-size: 50px;
					line-height: 45px;
				}
				@media (max-width: 575px) {
					margin-top: 20px;
					margin-bottom: 30px;
				}
			}
			.pow-content {
				margin-top: 50px;
				.understrap_social_elements {
					margin-top: 60px;
					p {
						margin: 0;
						font-weight: 500;
					}
					.social_facebook {
						margin: 6px 6px 6px 0px!important;
					}
				}
			}
			@media (min-width: 576px) {
			  .store-description-outer {
				  min-height: 550px;
				  display: table;
				  height: 550px;

				  .store-description-inner {
					  vertical-align: middle;
					  height: 100%;
					  display: table-cell;
				  }
			  }
			}
			.mobile-store-key-info {
				padding: 0 15px 50px;
				.pw-content {
                  	margin: 30px 0;
				}
			}
		}

		.post-wrap {
			padding-top: 0;
			margin-top: -20px;
		}
		&:not(.post-template-single-store) {
			.fs-wrap {
				@media (min-width: 768px) {
					margin-top: -170px;
				}
			}
		}
		.fs-wrap {
			background-color: $yellow_lt;
			clip-path: polygon(0 165px,100% 0%,100% calc(100% - 85px),0 100%);
			transform: translateZ(0);
			overflow: hidden;
			margin-bottom: -65px;
		}
	}
	&.post-template-single-store {
		#content {
			padding-right: 45px;
			padding-left: 45px;
			@media (max-width: 767px) {
				padding-right: 15px;
				padding-left: 15px;
			}
		}
		.left-col {
			@media (max-width: 767px) {
				order: 2;
				margin-bottom: -50px;
			}
		}
		.right-col {
			@media (max-width: 767px) {
				order: 1;
				#store-images-container {
					margin-top: 0;
				}
			}
		}
		.entry-title {
			font-size: 90px;
			font-weight: 900;
			color: $green_dk;
			margin-bottom: 0;
			@media (max-width: 767px) {
				font-size: 50px;
			}
		}
		.pw-key-info {
			// margin-top: -12px;
			span {
				padding: 0 10px;
				border-right: 1px solid;
			}
			span:first-child {
				padding-left: 0;
			}
			span:last-child {
				padding-right: 0;
				border-right: none;
			}
			@media (max-width: 767px) {
				margin-top: 0;
				span {
					display: block;
					margin-bottom: 2px;
					border: none;
					padding: 0;
				}
			}
			a {
				color: #5f8d69;
				text-decoration: underline;
				&:hover {
					color: #173826;
				}
			}
		}
		.pw-content {
			margin: 50px 0 60px;
			color: $black;
			.pwc-wrap {
				@include alltransition();
			}
			.pw-c-readmore {
				color: $green_dk;
				&:hover {
					text-decoration: underline;
				}
			}
			.after-read-more {
				display: none;
			}
			&.expand {
				.after-read-more {
					display: block;
				}
				.pwc-wrap {
					height: 100%;
					@include alltransition();
				}
			}
			.pw-excerpt {
				p:nth-child(2), .understrap-open-hours {
					display: none;
				}
			}
		}
		.understrap_social_elements {
			margin-bottom: 20px;
		}
		.store_schedule_wrapper {
			padding: 20px 20px 30px 20px;
			background-color: $green_mid;
			color: #fff;
			clip-path: polygon(0 0, 100% 0, 100% 100%, 0% calc(100% - 25px));
			transform: translateZ(0);
			margin-bottom: 25px;

			@media (max-width: 575px) {
				margin-bottom: 0;
			}

			.store_holidays {
				padding: 0 0 20px 10px;
				display: grid;
				grid-column-gap: 20px;
				grid-row-gap: 20px;
				grid-template-columns: repeat(3, 1fr);
				@media (max-width: 767px) {
					grid-template-columns: repeat(2, 1fr);
				}
				.sh-div {
					p {
						margin: 0;
						color: #fff;
						&.shd-title {
							font-size: 17px;
							font-weight: 800;
						}
					}
				}
			}
			.store_schedule {
				display: flex;
				justify-content: left;
				p {
					margin: 0;
					color: #fff;
				}
				.ss_weekdays {
					width: 65%;
					border-right: 1px solid $green_lt;
					@media (max-width: 767px) {
						width: 100%;
					}
					.ssw_wrap {
						display: flex;
					  flex-direction: row;
					  flex-wrap: wrap;
						.ss-days {
							width: 50%;
							height: auto;
							@media (max-width: 767px) {
								width: 100%;
							}
						}
					}
				}
				.ss_weekends {
					padding-left: 30px;
				}
				.ss-wraps {
					padding: 6px 20px 14px 10px;
					display: inline-block;
				}
				.wd-div, .we-div {
					// padding: 10px 0;
					.wdd-title, .wed-title {
						font-size: 17px;
						font-weight: 800;
					}
					.wdd-time, .wed-time {
						margin-top: -5px;
					}
					&.active {
						// padding: 10px 10px 15px 10px;
						position: relative;
						.ss-wraps {
							background-color: $green_gray;
							clip-path: polygon(0 0, 100% 0, 100% 100%, 0% calc(100% - 10px));
							transform: translateZ(0);
						}
						// &:before {
						// 	content: '';
						// 	position: absolute;
						// 	top: 0;
						// 	left: 0;
						// 	width: 80%;
						// 	height: 100%;
						// 	background-color: $green_gray;
						// 	clip-path: polygon(0 0, 100% 0, 100% 100%, 0% calc(100% - 10px));
						// }
					}
				}
			}
		}

		#store-images-container {
			display: flex;
			flex-direction: column;
			margin-top: -120px;
			.si-items {
				background-color: #fff;
				background-size: cover;
				background-repeat: no-repeat;
				background-position: center;
				&.wrapped {
					img {
						display: none;
					}
				}
			}
			.si-1 {
				clip-path: polygon(0% 0%, 100% 95px, 100% calc(100% - 25px), 0% 100%);
				transform: translateZ(0);
				height: 580px;
				position: relative;
				img {
					@include centerabsolute();
			    min-width: auto;
			    height: 110%;
			    max-width: initial!important;
				}
			}
			.si-bottom-wrap {
				display: inline-flex;
				height: 370px;
				clip-path: polygon(0 25px,100% 0,100% 100%,0 93%);
				transform: translateZ(0);
				margin-top: -9px;
				overflow: hidden;
				div {
					width: 50%;
					height: 371px;
					position: relative;
					overflow: hidden;
					img {
						@include centerabsolute();
						min-width: auto;
						height: 110%;
						max-width: initial!important;
					}
					&.si-2 {
						clip-path: polygon(0 0,100% 0,calc(100% - 15px) 100%,0 100%);
						transform: translateZ(0);
					}
					&.si-3 {
						clip-path: polygon(15px 0%,100% 0,100% 100%,0 100%);
						transform: translateZ(0);
					}
				}

			}
		}
		.fs-wrap {
			background-color: $yellow_lt;
			clip-path: polygon(0 9%,100% 0,100% calc(100% - 85px),0 100%);
			transform: translateZ(0);
			overflow: hidden;
    		margin-bottom: -65px;
			margin-top: -75px;
		}
	}

	.understrap-basic-cat-list {
		.ubcl-ul {
			list-style: none;
			padding: 0;
			li {
				margin-bottom: 15px;
			}
			.ubcl-item {
				display: flex;
				align-items: center;
				.ubcli-img {
					a {
						display: block;
						width: 110px;
						height: 110px;
						border: 8px solid #fff;
					}
				}
				.ubcli-content {
					font-size: 15px;
					font-family: 'Helvetica', sans-serif;
					padding-left: 15px;
					font-weight: 100;
					color: $black;
					a {
						color: $black;
						font-size: 15px;
						font-weight: 100;
						display: inline-block;
					}
					h3 {
						margin: 0 0 10px 0;
						font-size: 15px;
						font-family: 'Gilroy';
						a {
							color: $black;
							font-size: 20px;
							font-weight: 800;
						}
					}
				}
				.ubcli-read-more {
					color: $green_dk;
					display: none!important;
				}
			}
		}
	}

	.lazies {
		background-color: #fff;
		background-size: cover;
		background-repeat: no-repeat;
		background-position: center;
		&.wrapped {
			img {
				display: none;
			}
		}
	}

	&.home {
		.elementor-widget-text-editor {
			margin-bottom: 20px;
			.elementor-text-editor {
				p, span {
					font-size: 17px!important;
					font-family: 'Gilroy'!important;
					font-weight: 300;
				}
			}
		}
	}

	.last-col {
		order: 3;
		@media (max-width: 991px) {
			padding-left: 30px;
			padding-right: 30px;
			padding-top: 25px;
		}
	}
	.map-main-container {
		padding-top: 20px;

		@media (max-width: 991px) {
			padding-bottom: 35px;
		}

		.mobile-container {
			padding: 10px 25px 30px;
			width: 100%;
			display: block;
			&.hidden {
				display: none;
			}
			&.main-store-more-info {
				margin-top: -40px;
				padding-bottom: 15px;
				text-decoration: none;
				.store-more-info {
					background: #8E8179;
					padding: 30px 30px 40px;
					clip-path: polygon(0 23%,100% 0%,100% 77%,0 100%);
					transform: translateZ(0);
					width: 100%;
					span {
						color: #fff;
						font-size: 50px;
						font-weight: 800;
						display: block;
						text-align: center;
						&.store-name {
							line-height: 45px;
							padding-top: 30px;
						}
						&.small {
							color: #fff;
							font-size: 18px;
							font-weight: 400;
						}
					}
				}
			}

			.show-categories {
				margin-bottom: 80px;
			}
		}
		h5 {
			font-size: 55px;
			line-height: 50px;
			font-weight: 800;
			color: #173826;
			font-family: Gilroy,Sans-serif;
			position: relative;
		}
		a {
			cursor: pointer;
			&.green-btn {
				font-family: Gilroy,sans-serif;
				color: #fff;
				font-weight: 800;
				width: 100%;
				text-align: center;
				border: none;
				padding: 28px 0;
				font-size: 30px;
				background-color: #5f8c67;
				box-sizing: content-box;
				line-height: 35px;
				border-radius: 0;
				display: inline-block;
				&.right-taller {
					-webkit-clip-path: polygon(0 0,100% 0,100% 100%,0 88%);
					clip-path: polygon(0 0,100% 0,100% 100%,0 88%);
					transform: translateZ(0);
				}
				&.left-taller {
					-webkit-clip-path: polygon(0 0,100% 4%,100% 88%,0 100%);
					clip-path: polygon(0 0,100% 4%,100% 88%,0 100%);
					transform: translateZ(0);
				}

				text-decoration: none;
			}
		}
		p {
			font-size: 19px;
			line-height: 24px;
			padding: 35px 0 20px;
		}
		.col-left {
			padding: 0;
			img.north {
				height: 130px;
				position: absolute;
				bottom: 40px;
				left: 40px;
				@media (max-width: 991px) {
					height: 70px;
					left: 55px;
				}
			}
			.main-map {
				background: #eae5e1;
				height: 750px;
				position: relative;
				margin: 0 -30px;
				@media (max-width: 767px) {
					height: 70vh;
				}
				&:after {
					content: ' ';
					width: 100%;
					height: 100px;
					bottom: -1px;
					left: 1px;
					background: #fff;
					position: absolute;
					clip-path: polygon(0 100%,100% 0,100% 100%,0 100%);
					transform: translateZ(0);
					@media (max-width: 991px) {
						height: 55px;
						clip-path: polygon(0 95%,100% 0,100% 100%,0 100%);
					}
				}
				svg.map {
					height: 100%;
					width: 100%;
					margin-top: -3%;
					position: absolute;
					z-index: 1;
					.store-location {
						&.hover {
							fill: #255C8E;
						}
						&.hover-animation {
							animation: color_change 0.25s infinite alternate;
						}
					}
				}
				#interactive {
					display: inline-block;
					height: 100%;
					width: 100%;
				}
			}
		}
		&:not(.main-single-store-map) {
			.col-left {
				.main-map {
					&:before {
						content: ' ';
						width: 100%;
						height: 60px;
						top: -1px;
						left: 0;
						background: #fff;
						position: absolute;
						clip-path: polygon(0 0,100% 70%,100% 0,0% 0%);
						transform: translateZ(0);
						z-index: 1;
					}
					@media (max-width: 767px) {
						margin-top: -45px;
					}
				}
			}
		}
		.col-right {
			z-index: 2;

			#accordion {
				@media (min-width: 992px) {
					padding-top: 50px;
				}
				.card {
					border: none;
					background-color: transparent;
					.card-header {
						border: none;
						background-color: transparent;
					}
					.card-body {
						text-align: right;
						padding: 0 10px;
						a {
							font-size: 19px;
							line-height: 30px;
							padding-right: 10px;
							font-weight: normal;
							color: #173826;
							font-family: "Gilroy", Sans-serif;
							&:hover,
							&.hover {
								font-weight: bold;
								font-size: 22px;
								transition: all 0.5s;
								text-decoration: underline;
							}
						}
					}
					h5 {
						text-align: right;
						.btn {
							font-size: 50px;
							line-height: 55px;
							font-weight: 800;
							color: #173826;
							font-family: "Gilroy", Sans-serif;
							position: relative;
							padding: 3px 70px 3px 3px;
							transition: all 0.6s;

							&:before {
								content: '';
								width: 9px;
								height: 9px;
								position: absolute;
								right: 58px;
								bottom: 13px;
								background-color: #173826;
								border-radius: 50%;
							}

							&:after {
								content: '';
								width: 35px;
								height: 35px;
								background-image: url(/wp-content/uploads/svgs/map/minus_default.svg);
								position: absolute;
								right: 0;
								bottom: 11px;
							}

							&:not(.collapsed) {
								text-decoration: underline;
							}

							&:hover {
								&:after {
									background-image: url(/wp-content/uploads/svgs/map/minus_rollover.svg);
								}
							}

							&.collapsed {
								&:after {
									content: '';
									width: 35px;
									height: 35px;
									background-image: url(/wp-content/uploads/svgs/map/plus_default.svg);
									position: absolute;
									right: 0;
									bottom: 11px;
								}

								&:hover {
									&:after {
										background-image: url(/wp-content/uploads/svgs/map/plus_rollover.svg);
									}
								}
							}
						}
					}
				}
			}
		}

		&.main-single-store-map {
			padding-top: 0;
			margin-top: 20px;

			.mobile-container {
				&.main-store-more-info {
					margin-top: 0;
					@media (min-width: 992px) {
						position: absolute;
						right: 0;
						display: block;
						width: auto;
						top: 38%;
					}
				}
			}
			.col-left {
				.main-map {
					svg.map {
						height: 125%;
						max-height: 125%;
						margin-left: 0;
						margin-top: -8%;
						@media (max-width: 991px) {
							margin-left: 0;
							margin-top: -23%;
							height: 130%;
							max-height: 130%;
							//transform: rotateZ(50deg);
						}
					}
					#interactive {
						&:before {
							content: ' ';
							width: 100%;
							height: 60px;
							top: -50px;
							left: 0;
							background: #eae5e1;
							position: absolute;
							clip-path: polygon(0 0,0 100%,130% 100%,0 0);
							transform: translateZ(0);
						}
					}
				}
			}
		}

		&:not(.main-single-store-map) {
			@media (max-width: 991px) {
				.col-left, .col-right {
					display: none;

					&.show {
						display: block;
					}
				}
			}
		}
	}

	.elementor-section.elementor-section-boxed>.elementor-container {

	}

	&.page-template-category-page {
		#main-content {
			#page-wrapper {
				background-image: url('../images/store-category-bg.svg');
				background-position: top center;
				background-size: cover;
				background-repeat: no-repeat;
				z-index: -1;
				@media (max-width: 767px) {
					background-size: cover;
				}
			}
		}
	}
	&.page-id-829 {
		#main-content {
			#page-wrapper {
				background-image: url('../images/discover-takanini-bg-svg.svg');
				background-position: top center;
				background-size: 100%;
				background-repeat: no-repeat;
				z-index: -1;
				@media (max-width: 767px) {
					background-size: 470%;
					background-position: top;
				}
			}

		}
	}
	&.page-id-782 {
		#main-content {
			#page-wrapper {
				background-image: url('../images/takanini-leasing-bg-svg.svg');
				background-position: top center;
				background-size: 100%;
				background-repeat: no-repeat;
				z-index: -1;
				@media (max-width: 767px) {
					background-size: cover;
				}
			}

		}
	}
	#error-404-wrapper {
		height: 500px;
		#content {
			height: 100%;
			display: flex;
			align-items: center;
			justify-content: center;
			text-align: center;
			.error-404 {
				.error-title {
					font-size: 170px;
					font-weight: 800;
					margin-bottom: -50px;
					color: $green_dk;
				}
			}
			.error-back-home {
				margin-top: 20px;
				font-size: 20px;
			}
		}
		a {
			color: $green_dk;
			&:hover {
				color: lighten($green_dk, 10%);
			}
		}
		.btn {
			background-color: $green_dk;
			border-color: $green_dk;
			&:hover {
				background-color: lighten($green_dk, 10%);
			}
		}
		.input-group {
			input.field {
				border-color: $green_dk;
			}
		}
	}
	&:not(.e--ua-isTouchDevice) {
		.tk-news-layout, .tk-category-layout {
			article {

				.elementor-post__read-more {
					&:hover {
						text-decoration: underline;
					}
				}
				.elementor-post__title {
					a {
						&:hover {
							text-decoration: underline;
						}
					}
				}
				&:hover {
					.elementor-post__thumbnail__link {
						background-color: $green_lt;
					}
					.elementor-post__thumbnail {
						opacity: .1;
						@include alltransition(.5s);
					}
					.understrap-read-more-link {
						opacity: 1;
					}
				}
				&.category-derby-square {
					&:hover {
						.elementor-post__thumbnail {
							opacity: .05;
						}
						.elementor-post__thumbnail__link {
							background-color: $derby_black;
						}
						.understrap-read-more-link {
							.urml-text {
								font-weight: 800;
								img {
									width: 160px;
								}
							}
						}
					}
				}
			}
		}
	}
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

@-webkit-keyframes color_change {
	from { fill: #255C8E; }
	to { fill: #A1B7CE; }
}
@-moz-keyframes color_change {
	from { fill: #255C8E; }
	to { fill: #A1B7CE; }
}
@-ms-keyframes color_change {
	from { fill: #255C8E; }
	to { fill: #A1B7CE; }
}
@-o-keyframes color_change {
	from { fill: #255C8E; }
	to { fill: #A1B7CE; }
}
@keyframes color_change {
	from { fill: #255C8E; }
	to { fill: #A1B7CE; }
}
