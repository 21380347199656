.archive {
	.page-header {
	    background-color: #ddd;
	    padding: 20px;
	    margin: 0 20px 0;
	    .page-title {
	    	margin: 0;
	    }
	}
	.container-fluid {
		.page-header {
			margin: 0 -20px 20px;		
		}
	}

	article {
		display: flex;
		padding-bottom: 20px;
		margin-bottom: 20px;
		border-bottom: 1px dashed $grey;
		.entry-footer {
			display: none;
		}
		.entry-img {
			width: 200px;
			margin-right: 10px;
			img {
				width: 100%;
			}
		}
		.entry-content {
			.entry-header {
				.entry-title {
					a {
						color: $green_dk;
					}
				}
				.entry-meta {
					a {
						color: $green_dk;
					}
				}
			}
			.understrap-read-more-link {
				background-color: $green_lt;
				color: #fff;
				&:hover {
					background-color: $green_dk;
				}
			}
		}
	}
}
